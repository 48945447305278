import {createModel} from '@rematch/core';
import {RootModel} from '.';
import Api from 'utilities/Api';
import firestore, {db} from 'utilities/firebase/firestore';
import noSleep from 'utilities/noSleep';
import {argonKey, decrypted, encrypted} from 'utilities/crypto';
import {
  query,
  where,
  limit,
  getDocs,
  addDoc,
  collection,
  DocumentSnapshot,
  doc,
  writeBatch,
} from 'firebase/firestore';
import {Activity, PartEventData} from './parts';
import {signIn, signOut} from 'utilities/firebase/auth';
import sha256 from 'crypto-js/sha256';
import HEX from 'crypto-js/enc-hex';

interface State {
  name: string;
  token?: string;
  joinProgress?: number;
  uid?: string;
  activityKey?: string;
  activityDocId?: string;
  encryptionKey?: string;
  ratingUrl?: string;
  activity?: Activity;
  isIdle: boolean;
}

export const initialState: State = {name: '', isIdle: false};
export const participant = createModel<RootModel>()({
  state: initialState,
  selectors: (slice) => ({
    showEnterPin() {
      return slice((s) => s.name === '');
    },
    showCommentAnswer() {
      return slice((s) =>
        s.activity?.parts[0].step?.type === 'show_who' &&
        s.activity?.parts[0].step.revealing === false &&
        s.activity?.parts[0].step.results[0].name === s.name
          ? s.activity?.parts[0].step.results[0].answer
          : undefined,
      );
    },

    showThoughtShare() {
      return slice((s) =>
        s.activity?.parts[0].step?.type === 'thought_share'
          ? s.activity?.parts[0].step.name === s.name
          : false,
      );
    },

    activityPart() {
      return slice((s) => s.activity?.parts[0]);
    },

    firestoreQuery() {
      return slice((s) =>
        s.activityDocId && s.name
          ? doc(firestore, s.activityDocId, 'presenter', 'doc')
          : undefined,
      );
    },
    isIdle() {
      return slice((s) => s.isIdle);
    },
  }),
  reducers: {
    _saveActivityDocId(state, id: string) {
      state.activityDocId = id;
      return state;
    },
    _saveActivity(state, activity: Activity) {
      state.activity = activity;
      return state;
    },
    _saveName(state, name: string) {
      state.name = name;
      return state;
    },
    _setJoinProgress(state, progress: number | undefined) {
      state.joinProgress = progress;
      return state;
    },
    _handleJoinResponse(
      state,
      data: {
        ratingUrl: string;
        uid: string;
        encryptionKey: string;
        activityKey: string;
      },
    ) {
      const res: State = {
        ...state,
        ...data,
      };
      return res;
    },
    setIdle(state, idle: boolean) {
      state.isIdle = idle;
      return state;
    },
    clearState() {
      return initialState;
    },
    endParticipationToPresent() {
      return initialState;
    },
  },
  effects: (dispatch) => ({
    async joinActivity(input: {pin: string; name: string}, state) {
      noSleep.enable();
      dispatch.presenter.endPresentationToParticipate();
      const progress = dispatch.participant._setJoinProgress;
      if (!state.participant.activityDocId) {
        progress(0.4);
        const publicKey = await argonKey(
          input.pin,
          'team-mind-public',
          500,
          300,
        );
        progress(0.7);
        const {
          data,
        }: {
          data: {
            signed_key: string;
            rating_url: string;
            jwt: string;
            uid: string;
          };
        } = await Api.post('/activities/join', {
          pub_key: publicKey,
        });
        progress(0.75);
        await signIn(data.jwt);
        progress(0.8);
        const encryptionKey = await argonKey(
          input.pin,
          data.signed_key,
          100,
          300,
        );
        progress(0.9);
        dispatch.participant._handleJoinResponse({
          uid: data.uid,
          ratingUrl: data.rating_url,
          encryptionKey,
          activityKey: publicKey,
        });
        await dispatch.participant._getActivityDoc();
      }
      progress(0.95);
      await dispatch.participant._joinNewUser(input.name);
      progress(undefined);
    },

    async _getActivityDoc(_, state) {
      const {activityKey, encryptionKey} = state.participant;
      if (!activityKey || !encryptionKey) {
        return;
      }
      const q = query(
        firestore,
        where('activityKey', '==', activityKey),
        limit(1),
      );
      const activityDoc = (await getDocs(q)).docs[0];
      dispatch.participant._saveActivityDocId(activityDoc.id);
    },

    handleNewSnapshot(doc: DocumentSnapshot, state) {
      dispatch.participant._saveActivity(
        decrypted(doc.data()?.['activity'], state.participant.encryptionKey),
      );
    },

    async _joinNewUser(name: string) {
      await dispatch.participant.addEvent({
        type: 'join',
        data: {name},
      });
      dispatch.participant._saveName(name);
    },

    async _leaveUser(_, state) {
      await dispatch.participant.addEvent({
        type: 'leave',
        data: {name: state.participant.name},
      });
    },

    async addEvent(event: PartEventData, state) {
      noSleep.enable();
      const {activityDocId, encryptionKey, uid} = state.participant;
      if (!activityDocId || !encryptionKey || !uid) {
        return;
      }

      if (event.type === 'join') {
        const batch = writeBatch(db);
        batch.set(doc(firestore, activityDocId, 'participants', uid), {
          data: encrypted(
            {...event, partKey: state.participant.activity?.parts[0].key},
            encryptionKey,
          ),
        });
        const nameHash = HEX.stringify(sha256(event.data.name));
        batch.set(
          doc(firestore, activityDocId, 'participant-names', nameHash),
          {
            data: true,
          },
        );
        await batch.commit();
      } else {
        await addDoc(collection(firestore, activityDocId, 'participants'), {
          data: encrypted(
            {...event, partKey: state.participant.activity?.parts[0].key},
            encryptionKey,
          ),
        });
      }
    },

    async clickedCloseButton() {
      if (window.confirm('You are about to leave this activity.')) {
        await dispatch.participant._leaveUser();
        await dispatch.participant.clearState();
      }
    },

    async clearState() {
      noSleep.disable();
      await signOut();
    },
  }),
});
