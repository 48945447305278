import {useState} from 'react';
import {useDispatch, useSelector, useStateSelector} from 'utilities/hooks';
import {iOS} from 'utilities/platform';
import {PartGuessWho} from 'models/partGuessWho';
import IdleScreen from './IdleScreen';
import ParticipantCard from './ParticipantCard';
import SubmittedSuccessfullyCard from './SubmittedSuccessfullyCard';
import VisibilityTag from './VisibilityTag';

interface Props {
  part: PartGuessWho;
}

const GuessWho = ({part}: Props) => {
  const dispatch = useDispatch().partGuessWho;
  const showCommentAnswer = useSelector((s) => s.participant.showCommentAnswer);
  const loading = useStateSelector(
    (s) => s.loading.effects.partGuessWho.postCommentDone,
  );
  const [done, setDone] = useState(false);

  const step = part.step;
  if (!step) {
    return null;
  }

  return (
    <>
      {step.type === 'entering_answer' ? (
        <EnteringAnswer part={part} />
      ) : step.type === 'guessing_who' ? (
        <GuessingWho part={part} />
      ) : step.type === 'show_who' && showCommentAnswer && !done ? (
        <ParticipantCard
          className="w-full tablet:w-1/2"
          title={'Comment your answer'}
          subtitle={`Tap "done" after elaborating why you've entered "${showCommentAnswer}".`}
        >
          <button
            className="btn-primary w-full tablet:w-auto"
            disabled={loading}
            onClick={async () => {
              await dispatch.postCommentDone();
              setDone(true);
            }}
          >
            Done
          </button>
        </ParticipantCard>
      ) : (
        <IdleScreen />
      )}
    </>
  );
};

const EnteringAnswer = ({part}: Props) => {
  const dispatch = useDispatch().partGuessWho;
  const [answer, setAnswer] = useState('');
  const [done, setDone] = useState(false);
  const loading = useStateSelector(
    (s) => s.loading.effects.partGuessWho.postAnswer,
  );

  if (done) {
    return <SubmittedSuccessfullyCard />;
  }

  return (
    <ParticipantCard form title={part.attributes.question}>
      <input
        required
        autoFocus={!iOS()}
        placeholder={part.attributes.prompt}
        onChange={(e) => setAnswer(e.target.value)}
      />
      <VisibilityTag type={'all'} content={'answer'} />

      <button
        className="btn-primary w-full tablet:w-auto"
        disabled={loading || answer.trim().length === 0}
        onClick={async () => {
          await dispatch.postAnswer({answer});
          setDone(true);
        }}
      >
        Submit your answer
      </button>
    </ParticipantCard>
  );
};

const GuessingWho = ({part}: Props) => {
  const dispatch = useDispatch().partGuessWho;
  const step = part.step;

  const [guesses, setGuesses] = useState<
    {answer: string; name: string; index: number}[]
  >([]);
  const [done, setDone] = useState(false);
  const loading = useStateSelector(
    (s) => s.loading.effects.partGuessWho.postGuesses,
  );

  if (done) {
    return <SubmittedSuccessfullyCard />;
  }

  if (step?.type !== 'guessing_who') {
    return null;
  }
  const group = step.groups[0];

  return (
    <ParticipantCard
      className="w-full h-full tablet:w-1/2"
      title={'Guess who?'}
      subtitle={'Try to match the names with the given answers'}
    >
      {group.answers.map((answer, i) => (
        <div key={i}>
          <div className="flex items-center mb-2">
            <div className="dot" />
            <h1>{answer}</h1>
          </div>
          <div className="flex flex-wrap mb-6 ml-6">
            {group.names.map((name) => (
              <button
                className={`mb-2 mr-2 last:mr-0 ${
                  guesses.some((g) => g.name === name && g.index === i)
                    ? 'chip chip-selected'
                    : guesses.some((g) => g.name === name)
                    ? 'chip chip-muted'
                    : 'chip'
                }`}
                key={name}
                value={name}
                onClick={() => {
                  const newGuesses = guesses.filter(
                    (g) => g.name !== name && g.index !== i,
                  );
                  newGuesses.push({answer, name: name, index: i});
                  setGuesses(newGuesses);
                }}
              >
                {name}
              </button>
            ))}
          </div>
        </div>
      ))}
      <button
        className="btn-primary w-full tablet:w-auto"
        disabled={loading}
        onClick={async () => {
          await dispatch.postGuesses(
            guesses.map((g) => ({answer: g.answer, name: g.name})),
          );
          setDone(true);
        }}
      >
        Submit answer
      </button>
    </ParticipantCard>
  );
};

export default GuessWho;
