import {useState} from 'react';
import {useDispatch, useSelector, useStateSelector} from 'utilities/hooks';
import {Activity} from 'models/parts';
import {PartIntimacyReview} from 'models/partIntimacyReview';
import IdleScreen from './IdleScreen';
import ParticipantCard from './ParticipantCard';
import SubmittedSuccessfullyCard from './SubmittedSuccessfullyCard';
import VisibilityTag from './VisibilityTag';
import RadioChip from 'Presenter/components/RadioChip';

interface Props {
  part: PartIntimacyReview;
}

const IntimacyReview = ({part}: Props) => {
  const dispatch = useDispatch();
  const step = part.step;
  const [rating, setRating] = useState<number>();
  const [done, setDone] = useState(false);
  const [doneReading, setDoneReading] = useState(false);
  const [error, setError] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState<Activity>();
  const loading = useStateSelector(
    (s) => s.loading.effects.partIntimacyReview.makeReview,
  );
  const name = useStateSelector((s) => s.participant.name);
  const kind = useSelector((s) => s.presenter.activityKind);

  const safetyLevel = ['Basic', 'Moderate', 'Advanced'];

  if (!step) {
    return null;
  }
  return (
    <>
      {step.type === 'intimacy_rating' ? (
        !done ? (
          <ParticipantCard
            title={
              'How comfortable are you feeling at the moment in the current group setting?'
            }
            className="m-2"
          >
            <form className="flex flex-col justify-center tablet:block">
              <div className="flex flex-nowrap">
                {Array.from(Array(7).keys()).map((i) => (
                  <label
                    className={`w-full flex items-center justify-evenly ${
                      error ? 'border-danger' : 'border-accent'
                    } rounded-none border-y-2 border-x-0 first:border-l-2 first:rounded-l-lg last:rounded-r-lg last:border-r-2 ${
                      rating === i && 'bg-accent'
                    }`}
                    key={i}
                  >
                    <div
                      className={`w-5 h-5 my-2 rounded-full bg-primary ${
                        rating === i && 'bg-white'
                      }`}
                    >
                      <input
                        required
                        className="w-3 h-3 tablet:w-6 tablet:h-6 mx-1 my-1 tablet:mx-3 tablet:my-3 opacity-0 -translate-y-3 -translate-x-3"
                        type="radio"
                        value={i}
                        name={'rating'}
                        onChange={(e) => setRating(+e.target.value)}
                      />
                    </div>
                  </label>
                ))}
              </div>
              <div className="flex justify-between mb-4">
                <p>not at all</p>
                <p>absolutely</p>
              </div>
              <VisibilityTag
                type={'anon'}
                tag={'Anonymous'}
                caption={
                  "No one will see your answer. It's used to pick todays activity."
                }
              />

              <button
                className="btn-primary w-full tablet:w-auto"
                disabled={loading || rating === undefined}
                onClick={async () => {
                  try {
                    await dispatch.partIntimacyReview.makeReview(rating!);
                    setDone(true);
                  } catch {
                    setError(true);
                  }
                }}
              >
                Done
              </button>
            </form>
          </ParticipantCard>
        ) : (
          <SubmittedSuccessfullyCard title="Your answer submitted successfully!" />
        )
      ) : step.type === 'show_reading' ? (
        doneReading ? (
          <SubmittedSuccessfullyCard title="Waiting for others to finish." />
        ) : part.leader && part.leader === name ? (
          <div className="p-5 bg-white drop-shadow-md rounded-lg h-fit w-full">
            <h1 className="mb-4">As a leader select activity:</h1>

            <div className="flex items-center mb-2">
              <div className="dot" />
              <p className="caption">Length of the activity:</p>
            </div>

            <div className="mb-6">
              <RadioChip
                className="largeDesktop:mr-4 mb-2"
                selected={kind === 'long'}
                title={'Full activity'}
                onClick={() => dispatch.presenter.tappedActivityKind('long')}
              />
              <RadioChip
                className="mb-2 largeDesktop:mb-0"
                selected={kind === 'short'}
                title={'Quick activity'}
                onClick={() => dispatch.presenter.tappedActivityKind('short')}
              />
            </div>

            <div className="flex items-center mb-2">
              <div className="dot" />
              <p className="caption">Choose activity:</p>
            </div>

            {part.activities &&
              part.activities.map((a, i) => (
                <div key={a.id} className={'mb-4'}>
                  <RadioChip
                    selected={a === selectedActivity}
                    title={a.title}
                    onClick={() => setSelectedActivity(a)}
                  />

                  <p className="mb-0.5 text-sm">{a.description}</p>
                  <p className="caption mb-0.5">{'· ' + a.estimate[kind]}</p>
                  <p className="caption">
                    {'· Activities intimacy level: ' + safetyLevel[i]}
                  </p>
                </div>
              ))}

            <button
              className="btn-primary w-full mb-1"
              disabled={!!!selectedActivity}
              onClick={() => {
                setDoneReading(true);
                dispatch.partIntimacyReview.selectActivity({
                  activity: selectedActivity!,
                  kind,
                });
              }}
            >
              Continue
            </button>
          </div>
        ) : (
          <div className="h-screen w-full text-center mx-auto">
            <iframe
              className="h-2/3 w-full rounded-3xl mb-4"
              src={part.reading_url}
              title="Reading"
            />

            <button
              className="btn-primary"
              onClick={() => {
                dispatch.partIntimacyReview.doneReading();
                setDoneReading(true);
              }}
            >
              I'm done reading
            </button>
          </div>
        )
      ) : (
        <IdleScreen />
      )}
    </>
  );
};

export default IntimacyReview;
