import {createModel} from '@rematch/core';
import {RootModel, RootState} from '.';
import {
  getPart,
  PartOverview,
  participantsCountSelector,
  getParticipation,
  Activity,
  participantsCount,
} from './parts';

export interface PartIntimacyReview {
  key: string;
  layout: 'intimacy_review';
  attributes: {
    belongs_to: 'any';
  };
  step?: {
    type: 'show_intro' | 'intimacy_rating' | 'show_reading';
  };
  leader: string | null | undefined;
  reading_url: string | undefined;
  activities?: Activity[];
}
export type EventIntimacyReview =
  | {
      type: 'intimacy_review';
      data: {
        rating: number;
      };
    }
  | {
      type: 'intimacy_done_reading';
      data: {};
    }
  | {
      type: 'intimacy_select_activity';
      data: {
        activity: Activity;
        kind: 'long' | 'short';
      };
    };

export const partIntimacyReview = createModel<RootModel>()({
  state: null,
  selectors: () => ({
    expectedEvents() {
      return participantsCountSelector((count) => count);
    },
    overview() {
      return (): PartOverview => ({
        prefix: 'Start up with everyone making a *comfort levels* evaluation.',
      });
    },
    readingDone() {
      return (root: RootState) => {
        const step = root.presenter.activity?.parts[0].step;
        if (step?.type === 'show_reading') {
          const participantEvents = getParticipation(
            root,
            'intimacy_done_reading',
          );

          return participantEvents.length === participantsCount(root.presenter);
        } else {
          return false;
        }
      };
    },
  }),

  effects: (dispatch) => ({
    // Presenter
    startPart(_, state) {
      const part = getPart(state, 'intimacy_review');
      part.step = {type: 'show_intro'};
      dispatch.presenter.updateCurrentPart(part);
    },
    async nextStep(_, state) {
      const part = getPart(state, 'intimacy_review');
      switch (part.step?.type) {
        case 'show_intro':
          part.step = {type: 'intimacy_rating'};
          break;
        case 'intimacy_rating':
          dispatch.partIntimacyReview.getActivities();
          part.step = {type: 'show_reading'};
          break;
        case 'show_reading':
          const selectedActivities = getParticipation(
            state,
            'intimacy_select_activity',
          );

          const selectedActivity =
            selectedActivities && selectedActivities.length !== 0
              ? selectedActivities.slice(-1)[0]?.activity
              : part.activities !== undefined
              ? part.activities[0]
              : null;

          const kind =
            selectedActivities && selectedActivities.length !== 0
              ? selectedActivities.slice(-1)[0]?.kind
              : 'short';

          if (selectedActivity !== null) {
            dispatch.presenter.endIntimacyReview({
              activity: selectedActivity,
              kind,
            });
          }

          dispatch.presenter.endCurrentPart(undefined);
          return;
      }

      dispatch.presenter.updateCurrentPart(part);
    },

    async setActivities(activities: Activity[], state) {
      const part = getPart(state, 'intimacy_review');
      part.activities = activities;
      await dispatch.presenter.updateCurrentPart(part);
    },

    // Participant
    async makeReview(rating: number, state) {
      await dispatch.participant.addEvent({
        type: 'intimacy_review',
        data: {
          rating: rating,
        },
      });
    },

    async doneReading() {
      await dispatch.participant.addEvent({
        type: 'intimacy_done_reading',
        data: {},
      });
    },

    // Presenter
    async getActivities(_, state) {
      const ratings = getParticipation(state, 'intimacy_review').map(
        (r) => r.rating,
      );

      await dispatch.presenter._getActivities(ratings);
    },

    // Leader
    async selectActivity(
      input: {activity: Activity; kind: 'long' | 'short'},
      state,
    ) {
      await dispatch.participant.addEvent({
        type: 'intimacy_select_activity',
        data: {
          activity: input.activity,
          kind: input.kind,
        },
      });

      await dispatch.partIntimacyReview.doneReading();
    },
  }),
});
