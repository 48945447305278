import {useDispatch} from 'utilities/hooks';
import {PartChoice} from 'models/partChoice';
import Focus from './Focus';
import Say from './Say';

interface Props {
  part: PartChoice;
}

const Choice = ({part}: Props) => {
  const dispatch = useDispatch().partChoice;
  const step = part.step;
  if (!step) {
    return null;
  }

  return (
    <div className="relative h-full">
      {step.type === 'show_options' ? (
        <Say
          paragraphs={[part.attributes.message, 'Here are the options:']}
          reveal={part.attributes.options
            .map((o) => o.attributes.name)
            .join(', ')}
          onCompleted={dispatch.nextStep}
        />
      ) : step.type === 'picking_option' ? (
        <Focus />
      ) : step.type === 'show_result' ? (
        <Say
          paragraphs={['The option that got the most votes is:']}
          reveal={step.result.attributes.name}
          onCompleted={dispatch.nextStep}
        />
      ) : null}
    </div>
  );
};

export default Choice;
