import type {RootState} from 'models';
import type {EventChoice, PartChoice} from './partChoice';
import type {EventGuessWho, PartGuessWho} from './partGuessWho';
import {EventReview, PartReview} from './partReview';
import {EventThoughtShare, PartThoughtShare} from './partThoughtShare';
import deep from 'utilities/deep';
import {EventAnonymousHat, PartAnonymousHat} from './partAnonymousHat';
import {EventQuestionDeck, PartQuestionDeck} from './partQuestionDeck';
import {EventIntimacyReview, PartIntimacyReview} from './partIntimacyReview';

export interface Activity {
  id: string;
  title: string;
  description: string;
  nudge_text: string;
  safety_level: number;
  estimate: {
    long: string;
    short: string;
  };
  parts: Part[];
}

const activity = (state: RootState): Activity | undefined =>
  state.presenter.activity ?? state.participant.activity;

// PARTS

export type Part =
  | PartChoice
  | PartGuessWho
  | PartReview
  | PartIntimacyReview
  | PartThoughtShare
  | PartAnonymousHat
  | PartQuestionDeck;
export type PartLayout = Part['layout'];
type PartFor<T extends PartLayout> = Extract<Part, {layout: T}>;

export const getPart = <T extends PartLayout>(
  state: RootState,
  layout: T,
): PartFor<T> => {
  const part = activity(state)?.parts[0];
  if (!part || part.layout !== layout) {
    throw new Error(
      `Part type mismatch. Expected ${layout}, got ${part?.layout ?? '-'}`,
    );
  }
  return deep.copy(part) as PartFor<T>;
};

export type PartChoiceOutput = {question: string; prompt: string};
export type PartOutput = PartChoiceOutput | undefined;

// PART EVENTS

export type PartEventData =
  | EventJoin
  | EventLeave
  | EventChoice
  | EventGuessWho
  | EventReview
  | EventIntimacyReview
  | EventThoughtShare
  | EventAnonymousHat
  | EventQuestionDeck;
export type PartEvent = {partKey: string} & PartEventData;

type EventType = PartEvent['type'];
type EventDataFor<T extends EventType> = Extract<PartEvent, {type: T}>['data'];

export type EventJoin = {type: 'join'; data: {name: string}};
export type EventLeave = {type: 'leave'; data: {name: string}};

export const getParticipation = <T extends EventType>(
  state: RootState,
  type: T,
  roundIgnore?: 'roundIgnore',
): EventDataFor<T>[] => {
  const part = state.presenter.activity?.parts[0];
  return state.presenter.participants.flatMap((e) =>
    e.partKey === part?.key &&
    e.type === type &&
    (!roundIgnore &&
    e.data &&
    'round' in e.data &&
    part.step &&
    'round' in part.step
      ? e.data.round === part.step.round
      : true)
      ? e.data
      : [],
  ) as EventDataFor<T>[];
};

export const getCurrentStepParticipation = (state: RootState) => {
  const type = participantEventType(state);
  return type ? getParticipation(state, type) : undefined;
};

const participantEventType = (s: RootState) => {
  const stepType = activity(s)?.parts[0]?.step?.type;
  if (!stepType) {
    return undefined;
  }
  const stepToEvent = {
    // Choice
    picking_option: 'choice',
    // GuessWho
    entering_answer: 'guess_who_answer',
    guessing_who: 'guess_who_guess',
    // ThoughtShare
    entering_thought: 'thought_share_ready',
    // AnonymousHat
    entering_anonymous_short: 'anonymous_answer',
    show_anonymous: 'anonymous_vote_next',
    // Review
    rating: 'review',
    // IntimacyReview
    intimacy_rating: 'intimacy_review',
  } as const;

  type MappedSteps = keyof typeof stepToEvent;
  return stepType in stepToEvent
    ? stepToEvent[stepType as MappedSteps]
    : undefined;
};

export const participantsCountSelector =
  (events: (count: number, kind: 'short' | 'long') => number) =>
  (root: RootState) =>
    events(participantsCount(root.presenter), activityKind(root.presenter));

export const participantsCount = (s: {participants: PartEvent[]}) => {
  const joined = s.participants.flatMap((e) => (e.type === 'join' ? e : []));
  const leftNames = s.participants.flatMap((e) =>
    e.type === 'leave' ? e.data.name : [],
  );
  const result = joined.filter((e) => !leftNames.includes(e.data.name));
  return result.length;
};

export const activityKind = (s: {activityKind?: 'long' | 'short'}) =>
  s.activityKind ?? 'long';

// PART OVERVIEW

export type PartOverview = {
  prefix: string;
  description?: string | {option?: string; value: string}[];
  suffix?: string;
};

export const partOverviewSelector =
  <P extends Part>(
    overview: (part: P, kind: 'short' | 'long') => PartOverview,
  ) =>
  (
    root: RootState,
    {part, choice}: {part: P; choice?: PartOverview},
  ): PartOverview => {
    const partOverview = overview(part, root.presenter.activityKind ?? 'long');
    if (choice) {
      const lowercase = (str: string) =>
        str.charAt(0).toLowerCase() + str.slice(1);
      return {
        prefix: choice.prefix + lowercase(partOverview.prefix),
        description: choice.description,
        suffix: partOverview.suffix,
      };
    }
    return partOverview;
  };
