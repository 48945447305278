import {useState} from 'react';

interface Props {
  label?: string;
  placeholder?: string;
  value: string;
}

const Input = ({
  label,
  placeholder,
  value,
  ...rest
}: Props &
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >) => {
  const [focus, setFocused] = useState<boolean>();

  return (
    <>
      <div className="relative mt-4">
        <div
          className={
            focus || value.length > 0
              ? `absolute -top-2 left-4 z-10 font-nunito font-semibold text-xs bg-white px-1 pointer-events-none duration-300 ${
                  focus ? 'text-primary' : 'text-accent'
                }`
              : 'absolute top-2 left-[22px] italic text-base text-textCaption pointer-events-none duration-300'
          }
        >
          {label}
        </div>
      </div>
      <input
        value={value}
        placeholder={focus ? placeholder : ''}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        {...rest}
      />
    </>
  );
};

export default Input;
