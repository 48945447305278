import {useDispatch} from 'utilities/hooks';
import BouncyPhone from './BouncyPhone';
import Focus from './Focus';
import Say from './Say';
import {PartAnonymousHat} from 'models/partAnonymousHat';

interface Props {
  part: PartAnonymousHat;
}

const AnonymousHat = ({part}: Props) => {
  const dispatch = useDispatch().partAnonymousHat;
  const step = part.step;

  if (!step) {
    return null;
  }

  return (
    <div className="relative h-full">
      {step.type === 'show_intro' ? (
        <Say
          paragraphs={[
            part.attributes.message,
            step.kind === 'long'
              ? "You'll be able to submit as many responses as you want."
              : "You'll be able to submit a single response to the question.",
            'Anonymized responses will be discussed with whole team.',
          ]}
          onCompleted={dispatch.nextStep}
        />
      ) : step.type === 'entering_anonymous' ? (
        <Focus duration={180} onEnd={dispatch.nextStep} />
      ) : step.type === 'entering_anonymous_short' ? (
        <Focus />
      ) : step.type === 'show_anonymous' ? (
        <div className="sectionWrapper">
          <Say
            key={step.answers[0]}
            paragraphs={['Time to discuss:']}
            reveal={step.answers[0]}
            onCompleted={dispatch.nextStep}
          />
          {!step.revealing && (
            <BouncyPhone message={'Waiting for everyone to tap "done"'} />
          )}
        </div>
      ) : null}
    </div>
  );
};

export default AnonymousHat;
