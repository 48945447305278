import React from 'react';

interface Props {
  message: string | string[];
}

const BouncyPhone = ({message}: Props) => (
  <div className="mt-10 animate-fadeIn relative">
    <div className="absolute w-full top-16">
      <div className="bouncyBall w-6 h-6" />
    </div>
    <div className="mb-4 mx-auto p-2 w-20 aspect-[9/16] border-2 border-textCaption rounded-xl">
      <div className="mx-auto w-1 rounded-full  border-2 border-textCaption" />
      <div className="mx-auto h-full w-1/3  border-b-2 border-textCaption" />
    </div>
    {typeof message === 'string' ? (
      <h4 className="text-center">{message}</h4>
    ) : (
      message.map((m, i) => (
        <h4 key={i} className="text-center">
          {m}
        </h4>
      ))
    )}
  </div>
);

export default BouncyPhone;
