import {useEffect, useState} from 'react';
import {SiSlack} from 'react-icons/si';

import {useDispatch, useStateSelector} from 'utilities/hooks';

interface Props {
  onDone?: () => void;
}

const NudgeEmailForm = ({onDone}: Props) => {
  const dispatch = useDispatch().presenter;
  const loading = useStateSelector(
    (s) => s.loading.effects.presenter.updateNudgeEmail,
  );
  const teamEmail = useStateSelector((s) => s.presenter.team?.nudge_email);
  const [email, setEmail] = useState<string>();
  const [emailDone, setEmailDone] = useState(false);
  const [usingSlack, setUsingSlack] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (teamEmail) {
      setEmail(teamEmail);
    }
  }, [teamEmail]);

  useEffect(() => {
    const showTimeout = setTimeout(() => {
      setEmailDone(false);
    }, 3000);

    return () => clearTimeout(showTimeout);
  }, [emailDone]);

  return (
    <div>
      <p className="mb-4">
        {teamEmail
          ? "Make sure your team's email is up to date so you will get mindletic team notifications"
          : 'Add the email for your Slack or team so you will get mindletic team notifications'}
      </p>

      <input
        required
        placeholder={`${teamEmail ? 'update' : 'add'}_your_team@email.org`}
        className={`w-full ${!!error ? 'border-danger' : ''}`}
        value={email ?? ''}
        onChange={(e) => {
          setEmail(e.target.value);
          setEmailDone(false);
          setError(false);
        }}
      />

      <div className="tablet:flex items-center justify-between">
        {emailDone ? (
          <h4 className={'animate-fadeIn text-accent px-5'}>updated</h4>
        ) : (
          <button
            className={`${
              teamEmail ? 'btn-muted' : 'btn-primary'
            } w-full tablet:w-auto`}
            disabled={loading || !email || email.trim().length === 0}
            onClick={async () => {
              try {
                if (email) {
                  await dispatch.updateNudgeEmail(email);
                  setEmailDone(true);
                  onDone?.();
                }
              } catch (e) {
                setError(true);
                console.log(e);
              }
            }}
          >
            {teamEmail
              ? loading
                ? 'Updating...'
                : 'Update'
              : loading
              ? 'Submitting...'
              : 'Submit'}
          </button>
        )}
        {!usingSlack && (
          <button
            className="btn-muted w-full tablet:w-auto mt-4 tablet:mt-0"
            onClick={() => setUsingSlack(true)}
          >
            Using Slack?
          </button>
        )}
      </div>

      {!teamEmail && usingSlack && (
        <>
          <p className="caption mt-4">
            Instructions on how to find your Slack channel email can be found
            below or by clicking{' '}
            <a
              href="https://slack.com/help/articles/206819278-Send-emails-to-Slack#h_01F4WDZG8RTCTNAMR4KJ7D419V"
              target={'_blank'}
              rel="noreferrer"
            >
              here <SiSlack className="inline" />
            </a>
          </p>
          <img
            alt="slackEmail"
            src={require('assets/images/slackEmail.gif')}
            className="mt-4 w-auto mx-auto bg-no-repeat"
          />
        </>
      )}
    </div>
  );
};

export default NudgeEmailForm;
