import * as argon2 from 'argon2-browser';
import AES from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';

export const generatePin = () => {
  const pinLength = 6;
  var pin = '';
  const chars = 'ABCDEFGHIJKLMNPQRSTUVWXYZ123456789';
  for (var i = 0; i < pinLength; i++) {
    pin += chars.charAt(Math.floor(Math.random() * chars.length));
    if (i % 3 === 2 && i !== pinLength - 1) {
      pin += '-';
    }
  }
  return pin;
};

export const argonKey = async (
  pass: string,
  salt: string,
  time: number,
  wait: number = 50,
) => {
  // Fix for argon blocking the render cycle, loading does not start
  await new Promise((resolve) => setTimeout(resolve, wait));
  return (
    await argon2.hash({
      pass,
      salt,
      time,
      type: argon2.ArgonType.Argon2id,
    })
  ).hashHex;
};

export const decrypted = (data: any | undefined, key: string | undefined) =>
  key && data ? JSON.parse(AES.decrypt(data, key).toString(Utf8)) : undefined;

export const encrypted = (data: any | undefined, key: string | undefined) =>
  key && data ? AES.encrypt(JSON.stringify(data), key).toString() : undefined;
