import {createModel} from '@rematch/core';
import {jaroWinkler} from 'utilities/stringCompare';
import {RootModel, RootState} from '.';
import {
  getPart,
  getParticipation,
  PartOutput,
  partOverviewSelector,
  participantsCountSelector,
} from './parts';
export interface PartGuessWho {
  key: string;
  layout: 'guess_who';
  attributes: {
    belongs_to: 'any' | 'long' | 'short';
    message: string;
    question: string;
    prompt: string;
  };
  step?:
    | {
        type: 'show_intro' | 'entering_answer' | 'show_guessing_intro';
      }
    | {
        type: 'guessing_who';
        round: number;
        groups: {
          round: number;
          answers: string[];
          names: string[];
        }[];
      }
    | {
        type: 'show_who';
        revealing: boolean;
        results: {
          answer: string;
          name: string;
          success_rate: number;
        }[];
        groups: {
          round: number;
          answers: string[];
          names: string[];
        }[];
      }
    | {
        type: 'show_top_guesser';
        guessers: string[];
        success_rate: number;
      };
}

export type EventGuessWho =
  | {
      type: 'guess_who_answer';
      data: {
        answer: string;
        name: string;
      };
    }
  | {
      type: 'guess_who_guess';
      data: {
        name: string;
        round: number;
        guesses: {answer: string; name: string}[];
      };
    }
  | {
      type: 'guess_who_comment_done';
      data: {answer: string; name: string};
    };

export const partGuessWho = createModel<RootModel>()({
  state: null,
  selectors: () => ({
    expectedEvents() {
      return participantsCountSelector(
        (count) => (Math.ceil(count / 5) + 2) * count,
      );
    },
    overview() {
      return partOverviewSelector((part: PartGuessWho) => ({
        prefix: 'Everyone answers a question:',
        description: part.attributes.question,
        suffix: 'Then, everyone tries to *guess who* answered what.',
      }));
    },
    commentDone() {
      return (root: RootState) => {
        const step = root.presenter.activity?.parts[0].step;
        if (step?.type === 'show_who') {
          const participantEvents = getParticipation(
            root,
            'guess_who_comment_done',
          );
          return participantEvents.some(
            (e) =>
              e.answer === step.results[0].answer &&
              e.name === step.results[0].name,
          );
        } else {
          return false;
        }
      };
    },
  }),
  effects: (dispatch) => ({
    // Presenter
    startPart(data: PartOutput, state) {
      const part = getPart(state, 'guess_who');
      if (!part.attributes.question) {
        if (!data) {
          dispatch.presenter.endCurrentPart(undefined);
          return;
        }
        part.attributes.question = data.question;
        part.attributes.prompt = data.prompt;
      }
      part.step = {type: 'show_intro'};
      dispatch.presenter.updateCurrentPart(part);
    },
    nextStep(_, state) {
      const part = getPart(state, 'guess_who');
      switch (part.step?.type) {
        case 'show_intro':
          part.step = {type: 'entering_answer'};
          break;
        case 'entering_answer':
          part.step = {type: 'show_guessing_intro'};
          break;
        case 'show_guessing_intro':
          const events = getParticipation(state, 'guess_who_answer');
          const groups = groupAnswers(jaroSort(events)).map((g, i) => {
            return {
              round: i,
              answers: g.map((e) => e.answer),
              names: g.map((e) => e.name).sort(),
            };
          });
          if (groups.length > 0) {
            part.step = {type: 'guessing_who', round: groups[0].round, groups};
          } else {
            dispatch.presenter.endCurrentPart(undefined);
            return;
          }
          break;
        case 'guessing_who':
          const {round, names} = part.step.groups[0];
          const answers = getParticipation(state, 'guess_who_answer').filter(
            (a) => names.includes(a.name),
          );
          const results = getParticipation(state, 'guess_who_guess')
            .filter((g) => g.round === round)
            .reduce(
              (current, guesser, index) => {
                current.forEach((r) => {
                  const correct = +guesser.guesses.some(
                    (g) => r.answer === g.answer && r.name === g.name,
                  );
                  r.success_rate =
                    (r.success_rate * index + correct) / (index + 1);
                });
                return current;
              },
              answers.map((a) => ({...a, success_rate: 0})),
            );
          part.step = {
            type: 'show_who',
            revealing: true,
            results,
            groups: part.step.groups.splice(1),
          };
          break;
        case 'show_who':
          if (part.step.revealing) {
            part.step.revealing = false;
          } else if (part.step.results.length > 1) {
            // Loop through all the results
            part.step.results.shift();
            part.step.revealing = true;
          } else if (part.step.groups.length > 0) {
            // Loop through all groups
            part.step = {
              type: 'guessing_who',
              round: part.step.groups[0].round,
              groups: part.step.groups,
            };
          } else {
            // Show top guesser
            const answers = getParticipation(state, 'guess_who_answer');
            const roundGuessers = getParticipation(state, 'guess_who_guess');
            const scores = {} as Record<string, number>;
            roundGuessers.forEach((r) => {
              r.guesses.forEach((g) => {
                if (
                  answers.some(
                    (a) => a.answer === g.answer && a.name === g.name,
                  )
                ) {
                  scores[r.name] = (scores[r.name] ?? 0) + 1;
                }
              });
            });
            const topScore = Math.max(...Object.values(scores));
            const guessers = Object.entries(scores)
              .filter((s) => s[1] === topScore)
              .map((s) => s[0]);

            part.step = {
              type: 'show_top_guesser',
              guessers,
              success_rate: topScore / answers.length,
            };
          }
          break;
        case 'show_top_guesser':
          dispatch.presenter.endCurrentPart(undefined);
          return;
      }

      dispatch.presenter.updateCurrentPart(part);
    },
    // Participant
    async postAnswer(data: {answer: string}, state) {
      await dispatch.participant.addEvent({
        type: 'guess_who_answer',
        data: {...data, name: state.participant.name},
      });
    },
    async postGuesses(data: {answer: string; name: string}[], state) {
      const part = getPart(state, 'guess_who');
      await dispatch.participant.addEvent({
        type: 'guess_who_guess',
        data: {
          name: state.participant.name ?? '',
          round:
            part.step?.type === 'guessing_who' ? part.step.groups[0].round : 0,
          guesses: data,
        },
      });
    },
    async postCommentDone(_, state) {
      const part = getPart(state, 'guess_who');
      const answer =
        part.step?.type === 'show_who' ? part.step.results[0].answer : '';
      await dispatch.participant.addEvent({
        type: 'guess_who_comment_done',
        data: {answer, name: state.participant.name ?? ''},
      });
    },
  }),
});

const jaroSort = (data: {answer: string; name: string}[]) => {
  const placed = new Array<number>();
  return data.map((_, index, arr) => {
    let maxIndex = 0;
    if (index > 0) {
      const last = arr[placed[placed.length - 1]].answer;
      const similar = arr.map((a, i) =>
        placed.includes(i)
          ? -1
          : jaroWinkler(a.answer, last, {caseSensitive: false}),
      );
      maxIndex = similar.indexOf(Math.max(...similar));
    }
    placed.push(maxIndex);
    return arr[maxIndex];
  });
};

const groupAnswers = <T>(data: T[]) => {
  const groupCount = Math.ceil(data.length / 5);
  const result = new Array(groupCount).fill(0).map(() => []) as T[][];
  data.forEach((a, i) => result[i % groupCount].push(a));
  return result;
};
