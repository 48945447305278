import {useEffect, useState} from 'react';
import {useDispatch, useSelector, useStateSelector} from 'utilities/hooks';
import {PartQuestionDeck} from 'models/partQuestionDeck';
import IdleScreen from './IdleScreen';
import ParticipantCard from './ParticipantCard';
import SubmittedSuccessfullyCard from './SubmittedSuccessfullyCard';
import VisibilityTag from './VisibilityTag';

interface Props {
  part: PartQuestionDeck;
}

const QuestionDeck = ({part}: Props) => {
  const dispatch = useDispatch().partQuestionDeck;
  const loadingPicked = useStateSelector(
    (s) => s.loading.effects.partQuestionDeck.postQuestionDeckPicked,
  );
  const loadingShare = useStateSelector(
    (s) => s.loading.effects.partQuestionDeck.postQuestionDeckDone,
  );
  const [picked, setPicked] = useState(false);

  const questions = useSelector((s) => s.partQuestionDeck.choiceQuestions);
  const participantTurn = useSelector(
    (s) => s.partQuestionDeck.participantTurn,
  );

  const question = useSelector((s) => s.partQuestionDeck.pickedQuestion);

  const step = part.step;

  useEffect(() => {
    if (!participantTurn) {
      return;
    }
    if (step?.type === 'picking_question') {
      setPicked(false);
    }
  }, [participantTurn, step?.type]);

  if (!step) {
    return null;
  }

  return (
    <>
      {step.type === 'picking_question' ? (
        participantTurn ? (
          !picked ? (
            <ParticipantCard
              title={part.attributes.prompt}
              subtitle={'Pick a question for yourself to answer:'}
            >
              <div className="flex justify-center flex-wrap mb-4">
                {questions.map((q, i) => (
                  <button
                    className="btn-muted mx-1 mb-2"
                    key={i}
                    disabled={loadingPicked}
                    onClick={async () => {
                      await dispatch.postQuestionDeckPicked(q);
                      setPicked(true);
                    }}
                  >
                    {q}
                  </button>
                ))}
              </div>
              <VisibilityTag type={'all'} content={'selected question'} />
            </ParticipantCard>
          ) : (
            <SubmittedSuccessfullyCard
              title={'Question picked successfully!'}
            />
          )
        ) : (
          <IdleScreen />
        )
      ) : step.type === 'show_question' &&
        participantTurn &&
        !step.revealing ? (
        <ParticipantCard
          title={question}
          subtitle={
            step.kind === 'short'
              ? [
                  'Answer the question in a couple of sentences.',
                  "When you're done answering it, tap:",
                ]
              : "When you're done answering this question:"
          }
          className="w-full tablet:w-1/2"
        >
          <button
            className="btn-primary w-full tablet:w-auto"
            disabled={loadingShare}
            onClick={dispatch.postQuestionDeckDone}
          >
            Done
          </button>
        </ParticipantCard>
      ) : (
        <IdleScreen />
      )}
    </>
  );
};

export default QuestionDeck;
