import {MdPause, MdPlayArrow, MdSkipNext} from 'react-icons/md';

import {useDispatch, useSelector} from 'utilities/hooks';

const ProgressBar = () => {
  const dispatch = useDispatch().presenter;
  const isPaused = useSelector((s) => s.presenter.isPaused);
  const activityProgress = useSelector((s) => s.presenter.activityProgress);
  const current = useSelector((s) => s.presenter.currentProgressIndex);

  return (
    <>
      <div className="w-full flex h-8 largeScreen:h-10 gap-1">
        <button
          title={isPaused ? 'Resume activity' : 'Pause activity'}
          className="btn-muted justify-center items-center flex p-0 h-full aspect-square"
          onClick={dispatch.pressedPause}
        >
          {isPaused ? (
            <MdPlayArrow className="text-text" />
          ) : (
            <MdPause className="text-text" />
          )}
        </button>
        <button
          title={'Go to next step'}
          className="btn-muted justify-center items-center flex p-0 h-full aspect-square"
          onClick={dispatch.nextStep}
        >
          <MdSkipNext className="text-text" />
        </button>
        <div className="flex w-full gap-1 my-[1px]">
          {activityProgress.map((x, i) => (
            <div
              key={i}
              className={`${
                activityProgress.length === i + 1 ? 'flex-1' : 'flex-2'
              } bg-lighter flex h-full rounded-full`}
            >
              {current > i ? (
                <div className="bg-accent h-full w-full rounded-full" />
              ) : (
                current === i && (
                  <>
                    <div className="flex-1 flex flex-row">
                      <div
                        className="relative"
                        style={{
                          width: `${x * 100}%`,
                          transition: 'all 0.5s ease-in-out',
                        }}
                      >
                        <div className="bg-accent rounded-l-full absolute left-0 -right-4 largeScreen:-right-5 h-full">
                          <div className="h-full w-0 absolute right-0 flex justify-center">
                            <div className="bg-secondary h-full aspect-square rounded-full border-2 border-lighter" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="h-full aspect-square" />
                  </>
                )
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ProgressBar;
