import {useState} from 'react';
import {PartChoice} from 'models/partChoice';
import {useDispatch, useStateSelector} from 'utilities/hooks';
import IdleScreen from './IdleScreen';
import ParticipantCard from './ParticipantCard';
import SubmittedSuccessfullyCard from './SubmittedSuccessfullyCard';

interface Props {
  part: PartChoice;
}

const Choice = ({part}: Props) => {
  const dispatch = useDispatch().partChoice;
  const step = part.step;
  const [done, setDone] = useState(false);
  const loading = useStateSelector(
    (s) => s.loading.effects.partChoice.makeChoice,
  );
  if (!step) {
    return null;
  }
  return (
    <>
      {step.type === 'picking_option' ? (
        !done ? (
          <ParticipantCard
            title={'Make a choice'}
            subtitle={part.attributes.message}
          >
            <div className="flex justify-center flex-wrap">
              {part.attributes.options.map((o, i) => (
                <button
                  className="btn-muted mx-1 mb-2"
                  key={i}
                  disabled={loading}
                  onClick={async () => {
                    await dispatch.makeChoice(i);
                    setDone(true);
                  }}
                >
                  {o.attributes.name}
                </button>
              ))}
            </div>
          </ParticipantCard>
        ) : (
          <SubmittedSuccessfullyCard />
        )
      ) : (
        <IdleScreen />
      )}
    </>
  );
};

export default Choice;
