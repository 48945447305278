import {init, RematchDispatch, RematchRootState} from '@rematch/core';
import loading, {ExtraModelsFromLoading} from '@rematch/loading';
import immerPlugin from '@rematch/immer';
import selectPlugin from '@rematch/select';
import persistPlugin from '@rematch/persist';
import storage from 'redux-persist/lib/storage';
import {models, RootModel} from './models';
import {createMigrate} from 'redux-persist';
import {initialState as presenter} from 'models/presenter';
import {initialState as participant} from 'models/participant';

type FullModel = ExtraModelsFromLoading<RootModel>;

const migrations = {
  3: (state: any) => ({
    ...state,
    presenter,
    participant,
  }),
};

export const store = init<RootModel, FullModel>({
  models,
  plugins: [
    loading(),
    immerPlugin(),
    selectPlugin(),
    persistPlugin({
      key: 'root',
      version: 3,
      storage,
      blacklist: ['loading'],
      migrate: createMigrate(migrations, {debug: false}),
    }),
  ],
});

export type Store = typeof store;
export type Dispatch = RematchDispatch<RootModel>;
export type RootState = RematchRootState<RootModel, FullModel>;
