import {useEffect} from 'react';
import {useDispatch, useSelector} from 'utilities/hooks';
import BouncyPhone from './BouncyPhone';
import Say from './Say';
import {PartQuestionDeck} from 'models/partQuestionDeck';

interface Props {
  part: PartQuestionDeck;
}

const QuestionDeck = ({part}: Props) => {
  const dispatch = useDispatch().partQuestionDeck;
  const step = part.step;
  const each = useSelector((s) => s.partQuestionDeck.questionsEach);
  const question = useSelector((s) => s.partQuestionDeck.pickedQuestion);
  const questionPickingDone = useSelector(
    (s) => s.partQuestionDeck.questionPickingDone,
  );
  const commentDone = useSelector((s) => s.partQuestionDeck.commentDone);

  useEffect(() => {
    if (questionPickingDone || commentDone) {
      dispatch.nextStep();
    }
  }, [dispatch, questionPickingDone, commentDone]);

  if (!step) {
    return null;
  }

  return (
    <div className="relative h-full">
      {step.type === 'show_intro' ? (
        <Say
          paragraphs={[
            part.attributes.message, // Let's explore some thoughts about work
            `Each of you will answer ${each} question${each > 1 ? 's' : ''}.`,
            'When your turn comes up, pick from one of 3 options.',
          ]}
          onCompleted={dispatch.nextStep}
        />
      ) : step.type === 'picking_question' ? (
        <div className="animate-fadeIn">
          <Say
            key={step.names[0]}
            paragraphs={['Next one to pick their question:']}
            reveal={
              <>
                <h2 className="text-center mt-12 animate-fadeIn">
                  {step.names[0]}
                </h2>
                <BouncyPhone message={'Waiting for them to pick a question'} />
              </>
            }
          />
        </div>
      ) : step.type === 'show_question' ? (
        <div className="animate-fadeIn">
          <Say
            key={`${step.names[0]}-speaking`}
            paragraphs={[`Question that ${step.names[0]} picked:`]}
            reveal={<>{question}</>}
            onCompleted={dispatch.nextStep}
          />
          {!step.revealing && (
            <BouncyPhone message={'Tap "done" after your answer'} />
          )}
        </div>
      ) : null}
    </div>
  );
};

export default QuestionDeck;
