interface Props {
  title?: string;
}

const SubmittedSuccessfullyCard = ({title}: Props) => (
  <div className="sectionWrapper tablet:w-3/5">
    <h1>{title ?? 'Submitted successfully!'}</h1>
  </div>
);

export default SubmittedSuccessfullyCard;
