import {useState} from 'react';
import {useDispatch, useStateSelector} from 'utilities/hooks';
import {PartReview} from 'models/partReview';
import IdleScreen from './IdleScreen';
import ParticipantCard from './ParticipantCard';
import SubmittedSuccessfullyCard from './SubmittedSuccessfullyCard';
import VisibilityTag from './VisibilityTag';

interface Props {
  part: PartReview;
}

const Review = ({part}: Props) => {
  const dispatch = useDispatch();
  const step = part.step;
  const [rating, setRating] = useState<number>();
  const [done, setDone] = useState(false);
  const [error, setError] = useState(false);
  const loading = useStateSelector(
    (s) => s.loading.effects.partReview.makeReview,
  );

  if (!step) {
    return null;
  }
  return (
    <>
      {step.type === 'rating' ? (
        !done ? (
          <ParticipantCard
            title={"How comfortable did you feel during today's activity?"}
            className="m-2"
          >
            <form className="flex flex-col justify-center tablet:block">
              <div className="flex flex-nowrap">
                {Array.from(Array(7).keys()).map((i) => (
                  <label
                    className={`w-full flex items-center justify-evenly ${
                      error ? 'border-danger' : 'border-accent'
                    } rounded-none border-y-2 border-x-0 first:border-l-2 first:rounded-l-lg last:rounded-r-lg last:border-r-2 ${
                      rating === i && 'bg-accent'
                    }`}
                    key={i}
                  >
                    <div
                      className={`w-5 h-5 my-2 rounded-full bg-primary ${
                        rating === i && 'bg-white'
                      }`}
                    >
                      <input
                        required
                        className="w-3 h-3 tablet:w-6 tablet:h-6 mx-1 my-1 tablet:mx-3 tablet:my-3 opacity-0 -translate-y-3 -translate-x-3"
                        type="radio"
                        value={i}
                        name={'rating'}
                        onChange={(e) => setRating(+e.target.value)}
                      />
                    </div>
                  </label>
                ))}
              </div>
              <div className="flex justify-between mb-4">
                <p>not at all</p>
                <p>absolutely</p>
              </div>
              <VisibilityTag
                type={'anon'}
                tag={'Anonymous'}
                caption={
                  "No one will see your answer. It's used to pick future activities."
                }
              />

              <button
                className="btn-primary w-full tablet:w-auto"
                disabled={loading || rating === undefined}
                onClick={async () => {
                  try {
                    await dispatch.partReview.makeReview(rating!);
                    setDone(true);
                  } catch {
                    setError(true);
                  }
                }}
              >
                Done
              </button>
            </form>
          </ParticipantCard>
        ) : (
          <SubmittedSuccessfullyCard title="Review submitted successfully!" />
        )
      ) : (
        <IdleScreen />
      )}
    </>
  );
};

export default Review;
