import {createModel} from '@rematch/core';
import Api from 'utilities/Api';
import {RootModel} from '.';
import {getPart, PartOverview, participantsCountSelector} from './parts';

export interface PartReview {
  key: string;
  layout: 'review';
  attributes: {
    belongs_to: 'any';
    nudge: string;
  };
  step?: {
    type: 'show_intro' | 'rating' | 'show_done' | 'email_entry';
  };
}
export interface EventReview {
  type: 'review';
  data: undefined;
}

export const partReview = createModel<RootModel>()({
  state: null,
  selectors: () => ({
    expectedEvents() {
      return participantsCountSelector((count) => count);
    },
    overview() {
      return (): PartOverview => ({
        prefix: 'Finish up with everyone making a *comfort levels* evaluation.',
      });
    },
  }),
  effects: (dispatch) => ({
    // Presenter
    startPart(_, state) {
      dispatch.presenter.updateCurrentPart({
        key: 'review-key',
        layout: 'review',
        attributes: {
          nudge: state.presenter.activity?.nudge_text ?? '',
          belongs_to: 'any',
        },
        step: {type: 'show_intro'},
      });
    },
    async nextStep(_, state) {
      const part = getPart(state, 'review');
      switch (part.step?.type) {
        case 'show_intro':
          part.step = {type: 'rating'};
          break;
        case 'rating':
          part.step = {type: 'show_done'};
          break;
        case 'show_done':
          if (!state.presenter.team?.nudge_email) {
            part.step = {type: 'email_entry'};
            break;
          }
          await dispatch.partReview._endPart();
          return;
        case 'email_entry':
          await dispatch.partReview._endPart();
          return;
      }

      dispatch.presenter.updateCurrentPart(part);
    },
    async _endPart() {
      await dispatch.presenter.endActivity();
      dispatch.presenter.clearState(true);
    },

    // Participant
    async makeReview(rating: number, state) {
      const ratingUrl = state.participant.ratingUrl;
      if (ratingUrl) await Api.post(ratingUrl, {rating});
      await dispatch.participant.addEvent({type: 'review', data: undefined});
    },
  }),
});
