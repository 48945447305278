import {useEffect} from 'react';
import {useDispatch} from 'utilities/hooks';

const IdleScreen = () => {
  const dispatch = useDispatch().participant;

  useEffect(() => {
    dispatch.setIdle(true);

    return () => {
      dispatch.setIdle(false);
    };
  }, [dispatch]);

  return (
    <div className="absolute p-4 top-1/3">
      <div className="mx-auto w-2/3 aspect-video border-2 border-textCaption rounded-md" />
      <div className="mx-auto w-10 h-8 border-x-2 border-textCaption" />
      <div className="mx-auto -mb-24 w-28 h-0.5 rounded-md bg-textCaption" />

      <div className="bouncyBall mb-20" />
      <h3 className="text-center text-lighter">
        {"Focus on the presenter's screen"}
      </h3>
    </div>
  );
};

export default IdleScreen;
