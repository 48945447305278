import {useEffect, useState} from 'react';
import {useDispatch, useSelector, useTimer} from 'utilities/hooks';
import BouncyPhone from './BouncyPhone';
import Focus from './Focus';
import Say from './Say';
import {PartThoughtShare} from 'models/partThoughtShare';

interface Props {
  part: PartThoughtShare;
}

const ThoughtShare = ({part}: Props) => {
  const dispatch = useDispatch().partThoughtShare;
  const step = part.step;
  const hasVolunteer = useSelector((s) => s.partThoughtShare.hasVolunteer);
  const commentDone = useSelector((s) => s.partThoughtShare.commentDone);

  useEffect(() => {
    if (commentDone || hasVolunteer) {
      dispatch.nextStep();
    }
  }, [dispatch, commentDone, hasVolunteer]);

  if (!step) {
    return null;
  }

  return (
    <div className="relative h-full">
      {step.type === 'show_intro' ? (
        <Say
          paragraphs={[
            part.attributes.message,
            'Take notes to help you share later if you want.',
          ]}
          onCompleted={dispatch.nextStep}
        />
      ) : step.type === 'entering_thought' ? (
        <Focus duration={180} />
      ) : step.type === 'show_thought_share_intro' ? (
        <Say
          paragraphs={[
            "Everyone's ready.",
            'Those who feel comfortable will be able to share.',
            'Tap "Share next" on your phone if you feel like it.',
          ]}
          onCompleted={dispatch.nextStep}
        />
      ) : step.type === 'show_who_next' ? (
        <InviteToShare part={part} />
      ) : step.type === 'thought_share' ? (
        <div className="mt-10 animate-fadeIn">
          <h4 className="text-center mb-4">Let's hear it from</h4>
          <h1 className="text-center mb-6">{step.name}</h1>
          <BouncyPhone message={'Then tap "done" on your phone.'} />
        </div>
      ) : null}
    </div>
  );
};

const InviteToShare = ({part}: Props) => {
  const [lastCall, setLastCall] = useState(false);
  const {setTimeout, clearTimeout} = useTimer();
  useEffect(() => {
    if (part.step?.type !== 'show_who_next') {
      return;
    }
    const duration = part.step.round === 0 ? 20000 : 15000;
    const timeout = setTimeout(() => {
      setLastCall(true);
    }, duration);
    return () => clearTimeout(timeout);
  }, [part, setTimeout, clearTimeout]);

  if (part.step?.type !== 'show_who_next') {
    return null;
  }

  return (
    <>
      {lastCall ? (
        <Focus
          duration={15}
          prompt={'Waiting for someone to share...'}
          timerText={'Anyone?'}
          endPrompt={'If those who feel like it have shared, click:'}
        />
      ) : (
        <Focus
          duration={part.step.round === 0 ? 20 : 15}
          prompt={'Waiting for someone to share...'}
          timerText={part.step.round === 0 ? "Who's first?" : "Who's next?"}
          onEnd={() => setLastCall(true)}
        />
      )}
    </>
  );
};

export default ThoughtShare;
