import {store} from 'store';

const baseUrl = process.env.REACT_APP_API_BASE_URL;

function headers() {
  const headers = new Headers({
    'Content-Type': 'application/json',
    Accept: 'application/json',
  });

  const token = store.getState().presenter.token;
  if (token) {
    headers.append('Authorization', `Bearer ${token}`);
  }
  // headers.append('Accept-Language', store.state.language());

  return headers;
}

async function handleResponse(response: Response | void) {
  if (!response) {
    return;
  }
  if (response.status === 204) {
    return;
  }

  if (response.status >= 200 && response.status < 300) {
    return await response.json();
  }

  // if (response.status === 401) {
  //   store.dispatch.unauthenticated();
  // }

  const json = await response.json();

  // if (json) {
  //   const {message, errors} = json;

  //   if (message && !errors) {
  //     const t = i18n.getFixedT(null, 'components');
  //     Alert.alert(t('Api.alertTitle'), message);
  //     console.log('API Error', response);
  //   }

  //   if (message || errors) {
  //     throw {...(errors || {}), message, status: response.status};
  //   }
  // }

  throw json;
}

function handleNetworkError(error: Error) {
  throw error;
}

type RequestOptions = {
  method: string;
  headers: Headers;
  body?: any;
};
const request = (method: string) => (url: string, payload?: any) => {
  const options: RequestOptions = {method, headers: headers()};
  let queryParams = '';

  if (payload) {
    if (method === 'GET') {
      queryParams = `?${buildParams(payload)}`;
    } else {
      options.body = JSON.stringify(payload);
    }
  }

  const fullURL = url.includes('://')
    ? `${url}${queryParams}`
    : `${baseUrl}/api/teams${url}${queryParams}`;

  return fetch(fullURL, options).catch(handleNetworkError).then(handleResponse);
};

function buildParams(payload: any): URLSearchParams {
  const params = new URLSearchParams();
  for (const [key, value] of Object.entries<any>(payload)) {
    if (Array.isArray(value)) {
      for (const i in value) {
        params.append(key + '[]', value[i]);
      }
    } else if (value !== undefined) {
      params.append(key, value.toString());
    }
  }
  return params;
}

const Api = {
  get: request('GET'),
  post: request('POST'),
  patch: request('PATCH'),
  put: request('PUT'),
  delete: request('DELETE'),
};

export default Api;
