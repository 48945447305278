import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'utilities/hooks';
import {PartGuessWho} from 'models/partGuessWho';
import BouncyPhone from './BouncyPhone';
import Focus from './Focus';
import Say from './Say';

interface Props {
  part: PartGuessWho;
}

const GuessWho = ({part}: Props) => {
  const dispatch = useDispatch().partGuessWho;
  const step = part.step;
  const commentDone = useSelector((s) => s.partGuessWho.commentDone);

  useEffect(() => {
    if (commentDone) {
      dispatch.nextStep();
    }
  }, [dispatch, commentDone]);

  if (!step) {
    return null;
  }

  return (
    <div className="relative h-full">
      {step.type === 'show_intro' ? (
        <Say
          paragraphs={[part.attributes.message]}
          onCompleted={dispatch.nextStep}
        />
      ) : step.type === 'entering_answer' ? (
        <Focus />
      ) : step.type === 'show_guessing_intro' ? (
        <Say
          paragraphs={[
            'All answers are in.',
            'Time to guess who entered what!',
          ]}
          onCompleted={dispatch.nextStep}
        />
      ) : step.type === 'guessing_who' ? (
        <Focus />
      ) : step.type === 'show_who' ? (
        <>
          <Say
            key={step.results[0].name}
            paragraphs={[
              `Answer: "${step.results[0].answer}".`,
              `${(
                step.results[0].success_rate * 100
              ).toFixed()}% correctly guessed that it's by:`,
            ]}
            reveal={step.results[0].name}
            onCompleted={dispatch.nextStep}
          />
          {!step.revealing && (
            <BouncyPhone
              message={[
                `${step.results[0].name}, share why you've entered this.`,
                'Then tap "done" on your phone.',
              ]}
            />
          )}
        </>
      ) : step.type === 'show_top_guesser' ? (
        <div className="text-center">
          <Say
            paragraphs={[
              `The ${
                step.guessers.length > 1 ? 'ones' : 'one'
              } with most correct answers ${
                step.guessers.length > 1 ? 'are' : 'is'
              }:`,
            ]}
            reveal={
              step.guessers.length > 0 ? step.guessers.join(', ') : 'No one'
            }
            onCompleted={dispatch.nextStep}
          />
        </div>
      ) : null}
    </div>
  );
};

export default GuessWho;
