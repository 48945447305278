import QRCode from 'react-qr-code';
import {useStateSelector, useWindowSize} from 'utilities/hooks';
import {participantsCount} from 'models/parts';
import DotParagraph from './DotParagraph';

interface Props {
  pin: string;
}

const StartTraining = ({pin}: Props) => {
  const {height} = useWindowSize();
  const connectedParticipants = useStateSelector((s) =>
    participantsCount(s.presenter),
  );

  return (
    <div className="h-full flex flex-col justify-between">
      {connectedParticipants === 0 ? (
        <>
          <div>
            <h2 className="mb-4 text-center">Leader screen</h2>
            {[
              "Hey, it's still just you here.",
              "After this step, you'll be assigned a leader role.",
              'Share the next screen with your team and follow the guidelines together!',
            ].map((text, index) => (
              <DotParagraph key={index} text={text} />
            ))}
          </div>

          <div className="text-center mt-4">
            <h3>Scan this QR code to join as a leader</h3>
            <QRCode
              className="min-h-[8rem] my-2 largeDesktop:my-4 largeScreen:my-8 mx-auto"
              value={`${process.env.REACT_APP_APP_URL}/?pin=${pin}`}
              size={height ? height / 5 : 128}
            />
            <h4>
              <span>Or enter </span>
              <span className="text-primary font-semibold">{pin}</span>
              <span> at </span>
              <span className="underline">{process.env.REACT_APP_APP_URL}</span>
            </h4>
          </div>
        </>
      ) : (
        <>
          <div>
            <h2 className="mb-4 text-center">Balance Team</h2>
            {[
              'Join Mindletic Balance Team game to get closer to your teammates',
              'Learn something new about each other to be stronger together',
              'Have fun!',
            ].map((text, i) => (
              <div key={i} className="flex items-center translate-x-[15%]">
                <div className="dot" />
                <h3 className="scale-[.65] origin-left leading-snug">
                  {' '}
                  {text}
                </h3>
              </div>
            ))}
          </div>

          <div className="text-center mt-4">
            <h3>Scan this QR code to join</h3>
            <QRCode
              className="min-h-[8rem] my-2 largeDesktop:my-4 largeScreen:my-8 mx-auto"
              value={`${process.env.REACT_APP_APP_URL}/?pin=${pin}`}
              size={height ? height / 5 : 128}
            />
            <h4>
              <span>Or enter </span>
              <span className="text-primary font-semibold">{pin}</span>
              <span> at </span>
              <span className="underline">{process.env.REACT_APP_APP_URL}</span>
            </h4>
          </div>
        </>
      )}
    </div>
  );
};

export default StartTraining;
