import {createModel} from '@rematch/core';
import {RootModel, RootState, StoreModels} from '.';
import Api from 'utilities/Api';
import firestore from 'utilities/firebase/firestore';
import {
  doc,
  addDoc,
  updateDoc,
  setDoc,
  query,
  collection,
  DocumentData,
  QuerySnapshot,
} from 'firebase/firestore';
import AES from 'crypto-js/aes';
import {argonKey, decrypted, encrypted, generatePin} from 'utilities/crypto';
import {
  Activity,
  activityKind,
  getCurrentStepParticipation,
  Part,
  PartEvent,
  participantsCount,
  PartLayout,
  PartOutput,
  PartOverview,
} from './parts';
import {signIn, signOut} from 'utilities/firebase/auth';
import noSleep from 'utilities/noSleep';

export type State = {
  token?: string;
  pin?: string;
  activityKey?: string;
  activityDocId?: string;
  encryptionKey?: string;
  team?: Team;
  activity?: Activity;
  activities?: Activity[];
  activityKind?: 'long' | 'short';
  participants: PartEvent[];
  leaderParticipant?: string;
  progress: {total: number; index: number; choiceKey?: string};
  paused: boolean;
  focus: boolean;
  failureMessage?: string;
  activityEnded?: boolean;
  activityStarted: boolean;
  intimacyReviewEnded: boolean;
};

type Team = {
  nudge_email: string | null;
  reading_url: string;
};

type ActivityOverview = {
  description: string;
  estimate: Activity['estimate'];
  parts: PartOverview[];
};

export const initialState: State = {
  participants: [],
  progress: {total: 0, index: 0},
  paused: false,
  focus: false,
  activityStarted: false,
  intimacyReviewEnded: false,
};
export const presenter = createModel<RootModel>()({
  state: initialState,

  selectors: (slice) => ({
    isPresenting() {
      return slice((s) => s.token !== undefined);
    },
    pin() {
      return slice((s) => s.pin);
    },
    activity() {
      return slice((s) => s.activity);
    },
    activityKind() {
      return slice(activityKind);
    },
    activityPlan(models: StoreModels) {
      return (root: RootState) => {
        const s = slice(root);
        if (!s.activity) {
          return undefined;
        }
        const result: ActivityOverview = {
          description: s.activity.description,
          estimate: s.activity.estimate,
          parts: s.activity.parts
            .flatMap((part, i, allParts) => {
              if (!kindPredicate(s.activityKind)(part)) {
                return [];
              }
              const prevPart = i > 0 ? allParts[i - 1] : undefined;
              const prevChoice =
                prevPart?.layout === 'choice' ? prevPart : undefined;
              const choice = prevChoice
                ? models.partChoice.overview(root, {part: prevChoice})
                : undefined;

              switch (part.layout) {
                case 'guess_who':
                  return models.partGuessWho.overview(root, {part, choice});
                case 'thought_share':
                  return models.partThoughtShare.overview(root, {part, choice});
                case 'question_deck':
                  return models.partQuestionDeck.overview(root, {part, choice});
                case 'anonymous_hat':
                  return models.partAnonymousHat.overview(root, {part, choice});
                default:
                  return [];
              }
            })
            .concat(models.partReview.overview(root)),
        };
        return result;
      };
    },
    participantsCount() {
      return slice(participantsCount);
    },
    participantsNames() {
      return slice(participantsNames);
    },
    activityPart() {
      return slice((s) => s.activity?.parts[0]);
    },

    firestoreQuery() {
      return slice((s) =>
        s.activityDocId
          ? query(collection(firestore, s.activityDocId, 'participants'))
          : undefined,
      );
    },
    participantsProgress() {
      return (root: RootState) => getCurrentStepParticipation(root)?.length;
    },
    activityProgress(models: StoreModels) {
      return (root: RootState) => {
        const s = slice(root);
        const currentPart = s.activity?.parts[0];
        const choiceKey = s.progress.choiceKey;
        if (!currentPart) {
          return [];
        }
        const result = new Array<number>(s.progress.total)
          .fill(1, 0, s.progress.index)
          .fill(0, s.progress.index);

        const expectedEvents = (layout: PartLayout): number => {
          // Explicit selector usage to solve type reference cycle
          const choiceBoost = choiceKey
            ? models.partReview.expectedEvents(root)
            : 0;
          switch (layout) {
            case 'guess_who':
              return choiceBoost + models.partGuessWho.expectedEvents(root);
            case 'thought_share':
              return choiceBoost + models.partThoughtShare.expectedEvents(root);
            case 'question_deck':
              return choiceBoost + models.partQuestionDeck.expectedEvents(root);
            case 'anonymous_hat':
              return choiceBoost + models.partAnonymousHat.expectedEvents(root);
            case 'review':
              return choiceBoost + models.partReview.expectedEvents(root);
            case 'intimacy_review':
              return choiceBoost + models.partReview.expectedEvents(root);
            case 'choice':
              const next = s.activity?.parts[1]?.layout;
              return (
                models.partReview.expectedEvents(root) +
                (next ? expectedEvents(next) : 0)
              );
          }
        };

        const currentEvents = s.participants.filter(
          (e) =>
            e.partKey === currentPart.key ||
            (choiceKey && e.partKey === choiceKey),
        ).length;
        result[s.progress.index] =
          currentEvents / expectedEvents(currentPart.layout);

        return result;
      };
    },
    currentProgressIndex() {
      return slice((s) => s.progress.index);
    },
    isPaused() {
      return slice((s) => s.paused);
    },
    isFocused() {
      return slice((s) => s.focus);
    },
    sidebar() {
      return (root: RootState) => {
        const s = slice(root);
        const activityPart = s.activity?.parts[0];
        const activityStarted = s.activityStarted;
        const intimacyReviewEnded = s.intimacyReviewEnded;

        const startTraining =
          !activityStarted && s.pin === undefined && !s.activityEnded;
        const usersJoining = !activityStarted && s.pin !== undefined;

        const nudgeEmail =
          activityPart?.step === undefined &&
          !usersJoining &&
          !!s.activityEnded; // show on th last screen

        const activityProgress =
          !!activityPart?.step &&
          !usersJoining &&
          !nudgeEmail &&
          intimacyReviewEnded;
        const stepProgress =
          getCurrentStepParticipation(root) !== undefined &&
          intimacyReviewEnded;

        if (
          [
            startTraining,
            usersJoining,
            activityProgress,
            stepProgress,
            nudgeEmail,
          ].every((e) => !e)
        ) {
          return undefined;
        } else {
          return {
            startTraining,
            usersJoining,
            activityProgress,
            stepProgress,
            nudgeEmail,
          };
        }
      };
    },
    failureMessage() {
      return slice((s) => s.failureMessage);
    },
    loadingActivity() {
      return slice((s) => !s.activity);
    },
  }),

  reducers: {
    startActivity(state) {
      state.activityStarted = true;
      return state;
    },
    opened(state, token: string | undefined) {
      state.token = token;
      return state;
    },
    _handleActivitiesResponse(state, activities: Activity[]) {
      state.activities = activities;
      return state;
    },
    _selectActivity(state, activity: Activity) {
      state.activity = activity;
      return state;
    },
    _saveError(state, error: string) {
      state.failureMessage = error;
      return state;
    },
    _handleTeamResponse(state, team: Team) {
      state.team = team;
      return state;
    },
    tappedActivityKind(state, kind: 'long' | 'short') {
      state.activityKind = kind;
      return state;
    },
    clickedCreateTraining(state) {
      if (!state.activity) {
        return state;
      }
      state.activity.parts = state.activity.parts.filter(
        kindPredicate(state.activityKind),
      );
      state.progress = {
        total:
          state.activity.parts.filter((p) => p.layout !== 'choice').length + 1, // +1 for Review
        index: 0,
      };
      return state;
    },
    endIntimacyReview(
      state,
      input: {activity: Activity; kind: 'long' | 'short'},
    ) {
      state.activityKind = input.kind;
      state.intimacyReviewEnded = true;
      return state;
    },
    _savePin(state, pin: string) {
      state.pin = pin;
      return state;
    },
    _saveKeys(state, data: {encryptionKey: string; activityKey: string}) {
      state.encryptionKey = data.encryptionKey;
      state.activityKey = data.activityKey;
      return state;
    },
    _saveDocId(state, docId: string) {
      state.activityDocId = docId;
      return state;
    },
    _saveParticipantsData(state, data: PartEvent[]) {
      if (
        state.participants.length === 0 &&
        data.length === 1 &&
        data[0]?.type === 'join'
      ) {
        state.leaderParticipant = data[0].data.name;
      }
      state.participants = data;
      return state;
    },
    updateCurrentPart(state, part: Part) {
      const parts = state.activity?.parts;
      if (parts) {
        parts[0] = part;
      }
      return state;
    },
    endCurrentPart(state) {
      if (state.activity?.parts[0].layout !== 'choice') {
        state.progress.index++;
        state.progress.choiceKey = undefined;
      } else {
        state.progress.choiceKey = state.activity.parts[0].key;
      }
      state.activity?.parts.shift();
      return state;
    },
    pressedPause(state) {
      state.paused = !state.paused;
      return state;
    },
    setFocus(state, focus: boolean) {
      state.focus = focus;
      return state;
    },
    clearState(state, activityEnded: boolean = false) {
      return {...initialState, token: state.token, activityEnded};
    },
    resetState() {
      return initialState;
    },
  },

  effects: (dispatch) => ({
    async opened(_, state) {
      dispatch.participant.endParticipationToPresent();
      if (!activityInProgress(state.presenter)) {
        dispatch.presenter.clearState();
        await dispatch.presenter._getTeam();
      }
    },
    async _getTeam() {
      try {
        const {data} = await Api.get('/team');
        dispatch.presenter._handleTeamResponse(data);
        if (data.error_message !== null) {
          dispatch.presenter._saveError(data.error_message);
        }
      } catch (e: any) {
        if ('message' in e) {
          dispatch.presenter._saveError(String(e.message));
        }
      }
    },
    async updateNudgeEmail(nudge_email: string) {
      await Api.post('/team', {nudge_email});
    },
    async _getActivities(ratings: number[], state) {
      try {
        const pub_key = state.presenter.activityKey;
        const {data} = await Api.post('/activities/activities', {
          ratings,
          pub_key,
        });
        dispatch.presenter._handleActivitiesResponse(data);
        dispatch.partIntimacyReview.setActivities(data);
      } catch (e: any) {
        if ('message' in e) {
          dispatch.presenter._saveError(String(e.message));
        }
      }
    },
    async initializeActivity(_) {
      const pin = generatePin();
      dispatch.presenter._savePin(pin);
      const publicKey = await argonKey(pin, 'team-mind-public', 500);
      const {data} = await Api.post('/activities', {
        pub_key: publicKey,
      });
      await signIn(data.jwt);
      const encryptionKey = await argonKey(pin, data.signed_key, 100);
      dispatch.presenter._saveKeys({encryptionKey, activityKey: publicKey});
      await dispatch.presenter._createActivityDoc();
    },
    async _createActivityDoc(_, state) {
      const {activityKey, encryptionKey} = state.presenter;
      if (!activityKey || !encryptionKey) {
        return;
      }
      const encrypted = AES.encrypt(
        JSON.stringify(state.presenter.activity),
        encryptionKey,
      ).toString();
      const activityDoc = await addDoc(firestore, {activityKey});
      dispatch.presenter._saveDocId(activityDoc.id);
      await setDoc(doc(firestore, activityDoc.id, 'presenter', 'doc'), {
        activity: encrypted,
      });
    },

    handleNewSnapshot(snap: QuerySnapshot, state) {
      dispatch.presenter._saveParticipantsData(
        snap.docs.map((doc: DocumentData) =>
          decrypted(doc.data().data, state.presenter.encryptionKey),
        ),
      );
    },

    async endIntimacyReview(
      input: {activity: Activity; kind: 'long' | 'short'},
      state,
    ) {
      const pub_key = state.presenter.activityKey;
      const kind = state.presenter.activityKind;
      await Api.post('/activities/start', {
        pub_key,
        kind,
        activity_id: input.activity.id,
      });
      dispatch.presenter._selectActivity(input.activity);
      dispatch.presenter.clickedCreateTraining();
      await dispatch.presenter._startNextPart();
    },

    async clickedStartTraining(_, state) {
      const pub_key = state.presenter.activityKey;
      if (!pub_key) {
        return;
      }

      dispatch.presenter._selectActivity({
        id: '0',
        title: 'Intimacy Review',
        description: '',
        nudge_text: '',
        safety_level: 0,
        parts: [
          {
            key: 'a80852cf534d5f87',
            layout: 'intimacy_review',
            attributes: {
              belongs_to: 'any',
            },
            leader: state.presenter.leaderParticipant,
            reading_url: state.presenter.team?.reading_url,
          },
        ],
        estimate: {
          long: '',
          short: '',
        },
      });
      dispatch.presenter.startActivity();
      await dispatch.presenter._startIntimacyReview();
    },
    async updateCurrentPart() {
      noSleep.enable();
      await dispatch.presenter._syncPresenterData();
    },
    async endCurrentPart(data: PartOutput) {
      await dispatch.presenter._startNextPart(data);
    },
    async _startNextPart(data: PartOutput, state) {
      const part = state.presenter.activity?.parts[0]?.layout;
      switch (part) {
        case 'anonymous_hat':
          dispatch.partAnonymousHat.startPart(data);
          break;
        case 'choice':
          dispatch.partChoice.startPart();
          break;
        case 'guess_who':
          dispatch.partGuessWho.startPart(data);
          break;
        case 'thought_share':
          dispatch.partThoughtShare.startPart(data);
          break;
        case 'question_deck':
          dispatch.partQuestionDeck.startPart();
          break;
        case undefined:
          dispatch.partReview.startPart();
          break;
      }
    },
    async _startIntimacyReview(data: PartOutput, state) {
      if (state.partIntimacyReview) {
        return;
      }

      dispatch.partIntimacyReview.startPart();
    },
    async _syncPresenterData(_, state) {
      const {activityDocId, encryptionKey} = state.presenter;
      if (!activityDocId || !encryptionKey) {
        return;
      }
      await updateDoc(await doc(firestore, activityDocId, 'presenter', 'doc'), {
        activity: encrypted(state.presenter.activity, encryptionKey),
      });
    },

    nextStep(_, state) {
      const activityPart = state.presenter.activity?.parts[0];
      switch (activityPart?.layout) {
        case 'anonymous_hat':
          dispatch.partAnonymousHat.nextStep();
          break;
        case 'choice':
          dispatch.partChoice.nextStep();
          break;
        case 'guess_who':
          dispatch.partGuessWho.nextStep();
          break;
        case 'review':
          dispatch.partReview.nextStep();
          break;
        case 'intimacy_review':
          dispatch.partIntimacyReview.nextStep();
          break;
        case 'thought_share':
          dispatch.partThoughtShare.nextStep();
          break;
        case 'question_deck':
          dispatch.partQuestionDeck.nextStep();
          break;
      }
    },

    async endActivity(_, state) {
      if (!state.presenter.token) {
        return;
      }
      await Api.post('/activities/end', {pub_key: state.presenter.activityKey});
    },

    async clickedCloseButton(_, state) {
      if (activityInProgress(state.presenter)) {
        if (
          window.confirm(
            'You are about to close this activity and lose all progress.',
          )
        ) {
          dispatch.presenter.endActivity();
          await dispatch.presenter.clearState();
        }
      } else {
        await dispatch.presenter.clearState();
      }
    },

    endPresentationToParticipate() {
      dispatch.presenter.endActivity();
      dispatch.presenter.resetState();
    },

    async clearState() {
      noSleep.disable();
      await signOut();
      await dispatch.presenter._getTeam();
    },
  }),
});

const activityInProgress = (state: State) =>
  state.activity?.parts[0]?.step !== undefined;

const kindPredicate = (kind?: 'long' | 'short') => (p: Part) =>
  p.attributes.belongs_to === 'any' ||
  p.attributes.belongs_to === (kind ?? 'long');

export const participantsNames = (s: State) =>
  s.participants.flatMap((e) => {
    const leftNames = s.participants.flatMap((e) =>
      e.type === 'leave' ? e.data.name : [],
    );
    return e.type === 'join' && !leftNames.includes(e.data.name)
      ? e.data.name
      : [];
  });
