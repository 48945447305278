import {useSelector} from 'utilities/hooks';
import DotParagraph from './DotParagraph';

const CreateTraining = () => {
  const failureMessage = useSelector((s) => s.presenter.failureMessage);

  return (
    <div className="h-full relative">
      <div className="h-6 mb-4 w-32 mx-auto bg-[url(./assets/images/mindleticLogo.png)] bg-no-repeat" />
      <h1 className="text-center mb-8">Balance Team</h1>

      <DotParagraph
        text={'During our activities, your team will grow stronger together.'}
      />
      <DotParagraph
        text={
          'Foster psychological safety, an essential factor for effective teams.'
        }
      />
      <DotParagraph
        text={
          "Each activity is personalized based on your team's comfort and maturity level."
        }
      />

      {!failureMessage ? (
        <DotParagraph
          text={'The first joiner will be the leader for this activity.'}
        />
      ) : (
        <div className="btnBottomWrapper">
          <h3>{failureMessage}</h3>
        </div>
      )}
    </div>
  );
};

export default CreateTraining;
