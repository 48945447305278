const Footer = () => (
  <div className="mt-2 text-center mx-auto rounded-full w-fit bg-lighter/50">
    <p className="px-4 py-1.5 text-xs text-textCaption font-montserrat">
      {'🔒 Secured with E2EE • '}
      <a
        target="_blank"
        rel="noreferrer"
        className="text-textCaption desktop:no-underline hover:underline"
        href="https://www.mindletic.com/balance-team-privacy-policy"
      >
        Privacy policy
      </a>
    </p>
  </div>
);

export default Footer;
