import {useEffect, useState} from 'react';
import {useDispatch, useSelector, useTimer} from 'utilities/hooks';

interface Props {
  duration?: number;
  prompt?: string;
  timerText?: string;
  endPrompt?: string;
  onEnd?: () => void;
}

const Focus = ({duration = 90, prompt, timerText, endPrompt, onEnd}: Props) => {
  const dispatch = useDispatch().presenter;
  const isPaused = useSelector((s) => s.presenter.isPaused);
  const isFocused = useSelector((s) => s.presenter.isFocused);
  const [seconds, setSeconds] = useState(duration);
  const [visible, setVisible] = useState(false);
  const {setTimeout, setInterval, clearInterval} = useTimer();

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 2000);

    setTimeout(() => {
      dispatch.setFocus(true);
    }, 100);

    return () => {
      dispatch.setFocus(false);
    };
  }, [dispatch, setTimeout]);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds - (isPaused ? 0 : 1));
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [setTimeout, setInterval, clearInterval, isPaused, dispatch]);

  useEffect(() => {
    setSeconds(duration);
  }, [duration, prompt, timerText, endPrompt]);

  useEffect(() => {
    if (seconds === 0) {
      onEnd?.();
    }
  }, [onEnd, seconds, dispatch]);

  const timeLeft = {
    m: `${Math.floor((seconds / 60) % 60)}`.padStart(2, '0'),
    s: `${Math.floor(seconds % 60)}`.padStart(2, '0'),
  };

  return (
    <div className="flex flex-col items-center justify-center h-full opacity-100">
      <div
        className={`transition-all delay-50 duration-[2000ms] mx-auto ${
          isPaused || !visible
            ? ''
            : seconds < 1
            ? 'animate-pulse'
            : 'animate-bouncy'
        } ${
          isFocused
            ? 'w-10 h-10 mb-16 bg-secondary rounded-full'
            : 'h-full w-full bg-white rounded-lg'
        }`}
      />

      {visible && (
        <div className="absolute animate-fadeIn text-center largeDesktop:mt-8 largeScreen:mt-24">
          <div className="mb-4 mx-auto p-2 w-2/5 aspect-[9/16] border-2 border-textCaption rounded-xl">
            <div className="mx-auto w-1 rounded-full  border-2 border-textCaption" />
            <div className="mx-auto h-full w-1/3  border-b-2 border-textCaption" />
          </div>

          {seconds > 0 ? (
            <>
              <h4
                className={`${isFocused ? 'text-lighter' : 'text-text'} mt-4`}
              >
                {prompt ?? 'Continue on your phones'}
              </h4>

              <h1 className={isFocused ? 'text-accent' : 'text-primary'}>
                {timerText ??
                  (onEnd
                    ? `${timeLeft.m}:${timeLeft.s}`
                    : +timeLeft.m > 0
                    ? `${+timeLeft.m + 1} min left`
                    : `Last minute`)}
              </h1>
            </>
          ) : (
            !onEnd && (
              <>
                <h4 className={isFocused ? 'text-lighter' : 'text-text'}>
                  {endPrompt ?? 'Still waiting for everyone to finish'}
                </h4>
                <button
                  className={`${
                    isFocused ? 'btn-muted-darkTheme' : 'btn-muted'
                  } mt-2`}
                  onClick={dispatch.nextStep}
                >
                  Continue
                </button>
              </>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default Focus;
