import VisibilityTag from 'Participant/components/VisibilityTag';
import {useDispatch, useSelector, useStateSelector} from 'utilities/hooks';
import {participantsCount} from 'models/parts';

const UsersJoining = () => {
  const dispatch = useDispatch().presenter;
  const userCount = useSelector((s) => s.presenter.participantsCount);
  const userNames = useSelector((s) => s.presenter.participantsNames);
  const starting = useStateSelector(
    (s) => s.loading.effects.presenter.clickedStartTraining,
  );
  const connectedParticipants = useStateSelector((s) =>
    participantsCount(s.presenter),
  );

  return (
    <>
      {connectedParticipants === 0 ? (
        <div className="mb-2">
          <h4 className="inline">{'Join as a leader'}</h4>
        </div>
      ) : (
        <div className="mb-2">
          <h4 className="inline mr-2">{'Joined:'}</h4>
          <h2 className="inline">{userCount}</h2>
        </div>
      )}
      <div className="flex flex-wrap mb-2">
        {userNames.map((name, i) => (
          <div key={i} className="chip mr-2 mb-2 animate-fadeIn">
            {name}
          </div>
        ))}
      </div>

      {connectedParticipants === 0 && (
        <VisibilityTag type={'you'} caption={"Don't share your screen yet"} />
      )}

      <button
        className="btn-primary"
        disabled={starting || userCount === 0}
        onClick={dispatch.clickedStartTraining}
      >
        {starting ? 'Starting...' : "Let's start"}
      </button>
    </>
  );
};

export default UsersJoining;
