interface Props {
  title: string | React.ReactNode;
  subtitle?: string | string[];
  children?: React.ReactNode;
  className?: string;
  form?: boolean;
}

const ParticipantCard = ({
  children,
  title,
  subtitle,
  className,
  form,
}: Props) => {
  const multiple = Array.isArray(subtitle);
  const elements = multiple ? subtitle : [subtitle];

  const containerClassName = `sectionWrapper tablet:w-3/5 ${className}`;
  const containerChildren = (
    <>
      <h1 className="mb-2 tablet:mb-6">{title}</h1>
      {elements.length > 1 ? (
        elements.map((text, index) => (
          <div key={index} className="flex items-center mb-2 tablet:mb-4">
            <div className="dot" />
            <p>{text}</p>
          </div>
        ))
      ) : (
        <p className="whitespace-pre-line mb-2 tablet:mb-4">{elements[0]}</p>
      )}

      {children}
    </>
  );

  return form ? (
    <form className={containerClassName}>{containerChildren}</form>
  ) : (
    <div className={containerClassName}>{containerChildren}</div>
  );
};

export default ParticipantCard;
