import {createModel} from '@rematch/core';
import {RootModel, RootState} from '.';
import {
  activityKind,
  getPart,
  getParticipation,
  PartOutput,
  partOverviewSelector,
  participantsCountSelector,
} from './parts';
export interface PartAnonymousHat {
  key: string;
  layout: 'anonymous_hat';
  attributes: {
    belongs_to: 'any' | 'long' | 'short';
    message: string;
    question: string;
    prompt: string;
  };
  step?:
    | {
        type: 'show_intro' | 'entering_anonymous' | 'entering_anonymous_short';
        kind: 'long' | 'short';
      }
    | {
        type: 'show_anonymous';
        kind: 'long' | 'short';
        revealing: boolean;
        answers: string[];
        round: number;
      };
}

export type EventAnonymousHat =
  | {
      type: 'anonymous_answer';
      data: {
        answer: string;
      };
    }
  | {
      type: 'anonymous_vote_next';
      data: {
        round: number;
      };
    };

export const partAnonymousHat = createModel<RootModel>()({
  state: null,
  selectors: () => ({
    expectedEvents() {
      return (root: RootState) => {
        const answers = getParticipation(root, 'anonymous_answer').length;
        return participantsCountSelector(
          (count) => Math.max(2, answers) * (count + 1),
        )(root);
      };
    },
    overview() {
      return partOverviewSelector((part: PartAnonymousHat, kind) => ({
        prefix: `Everyone *anonymously writes* ${
          kind === 'short' ? 'an answer' : 'answers'
        } to:`,
        description: part.attributes.question,
        suffix: 'Then, team *publicly discuss* one answer at a time.',
      }));
    },
  }),
  effects: (dispatch) => ({
    // Presenter
    startPart(data: PartOutput, state) {
      const part = getPart(state, 'anonymous_hat');
      if (!part.attributes.question) {
        if (!data) {
          dispatch.presenter.endCurrentPart(undefined);
          return;
        }
        part.attributes.question = data.question;
        part.attributes.prompt = data.prompt;
      }
      part.step = {type: 'show_intro', kind: activityKind(state.presenter)};
      dispatch.presenter.updateCurrentPart(part);
    },
    nextStep(_, state) {
      const part = getPart(state, 'anonymous_hat');
      const kind = activityKind(state.presenter);
      switch (part.step?.type) {
        case 'show_intro':
          part.step = {
            type:
              part.step.kind === 'short'
                ? 'entering_anonymous_short'
                : 'entering_anonymous',
            kind,
          };
          break;
        case 'entering_anonymous':
        case 'entering_anonymous_short':
          const answers = getParticipation(state, 'anonymous_answer');
          part.step = {
            type: 'show_anonymous',
            kind,
            revealing: true,
            answers: answers.map((a) => a.answer),
            round: 0,
          };
          break;
        case 'show_anonymous':
          if (part.step.revealing) {
            part.step.revealing = false;
          } else if (part.step.answers.length > 1) {
            part.step.answers.shift();
            part.step.round++;
            part.step.revealing = true;
          } else {
            dispatch.presenter.endCurrentPart(undefined);
            return;
          }
          break;
      }

      dispatch.presenter.updateCurrentPart(part);
    },
    // Participant
    async postAnswer(data: {answer: string}) {
      await dispatch.participant.addEvent({
        type: 'anonymous_answer',
        data,
      });
    },

    async postVoteNext(_, state) {
      const part = getPart(state, 'anonymous_hat');
      await dispatch.participant.addEvent({
        type: 'anonymous_vote_next',
        data: {
          round: part.step?.type === 'show_anonymous' ? part.step.round : 0,
        },
      });
    },
  }),
});
