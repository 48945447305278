import app from './app';
import {
  getAuth,
  signInWithCustomToken,
  signOut as signOutFir,
} from 'firebase/auth';

export const auth = getAuth(app);
export const signIn = (token: string) => signInWithCustomToken(auth, token);
export const signOut = () => signOutFir(auth);
