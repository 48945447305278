import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Participant from './Participant';
import Presenter from './Presenter';
import {Provider as StoreProvider} from 'react-redux';
import {store} from './store';

function App() {
  return (
    <StoreProvider store={store}>
      <Router>
        <Routes>
          <Route index element={<Participant />} />
          <Route path="/present/:token" element={<Presenter />} />
        </Routes>
      </Router>
    </StoreProvider>
  );
}

export default App;
