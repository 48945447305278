import {useEffect, useState} from 'react';
import {useDispatch, useSelector, useStateSelector} from 'utilities/hooks';
import {PartThoughtShare} from 'models/partThoughtShare';
import IdleScreen from './IdleScreen';
import ParticipantCard from './ParticipantCard';
import VisibilityTag from './VisibilityTag';

interface Props {
  part: PartThoughtShare;
}

const ThoughtShare = ({part}: Props) => {
  const dispatch = useDispatch().partThoughtShare;
  const loadingVolunteer = useStateSelector(
    (s) => s.loading.effects.partThoughtShare.postThoughtShareVolunteer,
  );
  const loadingShare = useStateSelector(
    (s) => s.loading.effects.partThoughtShare.postThoughtShareDone,
  );
  const loading = loadingVolunteer || loadingShare;
  const [done, setDone] = useState(false);
  const [notes, setNotes] = useState('');
  const [volunteered, setVolunteered] = useState(false);
  const showThoughtShare = useSelector((s) => s.participant.showThoughtShare);

  const step = part.step;

  useEffect(() => {
    if (step?.type === 'thought_share' && showThoughtShare) {
      setVolunteered(true);
    }
  }, [step?.type, showThoughtShare]);

  if (!step) {
    return null;
  }

  return (
    <>
      {step.type === 'entering_thought' ? (
        <EnteringAnswer part={part} notes={notes} setNotes={setNotes} />
      ) : step.type === 'show_who_next' && !volunteered ? (
        <ParticipantCard
          title={"If you'd like to share, please tap a button on your screen:"}
        >
          <button
            className="btn-primary w-full tablet:w-auto"
            disabled={loading}
            onClick={dispatch.postThoughtShareVolunteer}
          >
            Share next
          </button>
        </ParticipantCard>
      ) : step.type === 'thought_share' && showThoughtShare && !done ? (
        <ParticipantCard
          className="sectionWrapper w-full tablet:w-1/2"
          title={'Here are your notes:'}
          subtitle={notes}
        >
          <button
            className="btn-primary w-full tablet:w-auto"
            disabled={loading}
            onClick={async () => {
              await dispatch.postThoughtShareDone();
              setDone(true);
            }}
          >
            Done
          </button>
        </ParticipantCard>
      ) : (
        <IdleScreen />
      )}
    </>
  );
};

interface EnteringAnswerProps {
  part: PartThoughtShare;
  notes: string;
  setNotes: (newValue: string) => void;
}

const EnteringAnswer = ({part, notes, setNotes}: EnteringAnswerProps) => {
  const dispatch = useDispatch().partThoughtShare;
  const [done, setDone] = useState(false);

  return (
    <>
      <ParticipantCard title={part.attributes.question}>
        <textarea
          required
          autoFocus={false}
          className="mb-4 block w-full tablet:w-3/5"
          placeholder={part.attributes.prompt}
          onChange={(e) => {
            setNotes(e.target.value);
          }}
        />
        {done ? (
          <>
            <VisibilityTag type={'you'} content={'notes'} />
            <p>Got it. Waiting for others.</p>
            <p>You can keep editing your notes if you want.</p>
          </>
        ) : (
          <>
            <VisibilityTag type={'you'} content={'notes'} />

            <button
              className="btn-primary w-full tablet:w-auto"
              disabled={notes.trim().length === 0}
              onClick={async () => {
                await dispatch.postThoughtShareReady();
                setDone(true);
              }}
            >
              Ready
            </button>
          </>
        )}
      </ParticipantCard>
    </>
  );
};

export default ThoughtShare;
