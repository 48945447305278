import VisibilityTag from 'Participant/components/VisibilityTag';
import {useDispatch, useSelector} from 'utilities/hooks';

const StartActivity = () => {
  const dispatch = useDispatch().presenter;
  const failureMessage = useSelector((s) => s.presenter.failureMessage);

  return (
    <>
      <h3 className="mb-2">Activity leader sign in:</h3>
      <VisibilityTag type={'you'} caption={"Don't share your screen yet"} />

      <button
        className="btn-primary"
        onClick={dispatch.initializeActivity}
        disabled={!!failureMessage}
      >
        Let's begin
      </button>
    </>
  );
};

export default StartActivity;
