import {Models, RematchRootState} from '@rematch/core';
import {presenter} from './presenter';
import {participant} from './participant';
import {partAnonymousHat} from './partAnonymousHat';
import {partChoice} from './partChoice';
import {partGuessWho} from './partGuessWho';
import {partReview} from './partReview';
import {partThoughtShare} from './partThoughtShare';
import {StoreSelectors} from '@rematch/select';
import {partQuestionDeck} from './partQuestionDeck';
import {partIntimacyReview} from './partIntimacyReview';

export interface RootModel extends Models<RootModel> {
  presenter: typeof presenter;
  participant: typeof participant;
  partAnonymousHat: typeof partAnonymousHat;
  partChoice: typeof partChoice;
  partGuessWho: typeof partGuessWho;
  partReview: typeof partReview;
  partIntimacyReview: typeof partIntimacyReview;
  partThoughtShare: typeof partThoughtShare;
  partQuestionDeck: typeof partQuestionDeck;
}

interface ExtraModel extends Models<RootModel> {}
export type StoreModels = StoreSelectors<RootModel, ExtraModel>;

export const models: RootModel = {
  presenter,
  participant,
  partAnonymousHat,
  partChoice,
  partGuessWho,
  partReview,
  partIntimacyReview,
  partThoughtShare,
  partQuestionDeck,
};

export type RootState = RematchRootState<RootModel, Record<string, never>>;
