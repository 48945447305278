import {createModel} from '@rematch/core';
import {RootModel, RootState} from '.';
import {
  activityKind,
  getPart,
  getParticipation,
  partOverviewSelector,
  participantsCount,
  participantsCountSelector,
} from './parts';
import {participantsNames} from './presenter';
import _ from 'lodash';

export interface PartQuestionDeck {
  key: string;
  layout: 'question_deck';
  attributes: {
    belongs_to: 'any' | 'long' | 'short';
    message: string;
    prompt: string;
    questions: string[];
  };
  step?:
    | {
        type: 'show_intro';
      }
    | {
        type: 'picking_question';
        round: number;
        names: string[];
        questions: string[];
      }
    | {
        type: 'show_question';
        kind: 'long' | 'short';
        revealing: boolean;
        round: number;
        question: string;
        names: string[];
      };
}

export type EventQuestionDeck =
  | {
      type: 'question_deck_picked';
      data: {
        round: number;
        name: string;
        question: string;
      };
    }
  | {
      type: 'question_deck_done';
      data: {
        round: number;
        name: string;
      };
    };

export const partQuestionDeck = createModel<RootModel>()({
  state: null,
  selectors: () => ({
    expectedEvents() {
      return participantsCountSelector(
        (count, kind) => numberOfRounds(count, kind) * 2 * count,
      );
    },
    overview() {
      return partOverviewSelector((part: PartQuestionDeck, kind) => ({
        prefix: 'One teammate at a time *draws 3 questions* from a deck, e.g.:',
        description: part.attributes.questions.slice(0, 3).map((value) => ({
          value,
        })),
        suffix:
          kind === 'long'
            ? 'They *pick one* and share their answer to the team.'
            : 'They *pick one* and share their brief answer to the team.',
      }));
    },
    // Presenter
    questionsEach() {
      return participantsCountSelector(numberOfRounds);
    },
    pickedQuestion() {
      return (root: RootState) => {
        const step = getPart(root, 'question_deck').step;
        if (step?.type === 'show_question') {
          return step.question;
        } else {
          return '';
        }
      };
    },
    questionPickingDone() {
      return (root: RootState) => {
        const step = getPart(root, 'question_deck').step;
        if (!step || step.type === 'show_intro') {
          return false;
        }
        const participation = getParticipation(
          root,
          'question_deck_picked',
        ).map((e) => e.name);
        return participation.includes(step.names[0]);
      };
    },
    commentDone() {
      return (root: RootState) => {
        const step = getPart(root, 'question_deck').step;
        if (!step || step.type === 'show_intro') {
          return false;
        }
        const participation = getParticipation(root, 'question_deck_done').map(
          (e) => e.name,
        );
        return participation.includes(step.names[0]);
      };
    },
    // Participant
    participantTurn() {
      return (root: RootState) => {
        const step = getPart(root, 'question_deck').step;
        if (!step || step.type === 'show_intro') {
          return false;
        } else {
          return step.names[0] === root.participant.name;
        }
      };
    },
    choiceQuestions() {
      return (root: RootState) => {
        const step = getPart(root, 'question_deck').step;
        if (
          step?.type !== 'picking_question' ||
          step?.names[0] !== root.participant.name
        ) {
          return [];
        } else {
          return step.questions;
        }
      };
    },
  }),
  effects: (dispatch) => ({
    // Presenter
    startPart(_, state) {
      const part = getPart(state, 'question_deck');
      part.step = {type: 'show_intro'};
      dispatch.presenter.updateCurrentPart(part);
    },
    nextStep(_, state) {
      const part = getPart(state, 'question_deck');
      switch (part.step?.type) {
        case 'show_intro':
          part.step = {
            type: 'picking_question',
            names: shuffleParticipants(state),
            questions: pickQuestions(state),
            round: 0,
          };
          break;
        case 'picking_question':
          part.step = {
            type: 'show_question',
            kind: activityKind(state.presenter),
            revealing: true,
            names: part.step.names,
            question:
              getParticipation(state, 'question_deck_picked').find(
                (q) =>
                  part.step?.type === 'picking_question' &&
                  part.step.names.length > 0 &&
                  q.name === part.step.names[0],
              )?.question ?? '',
            round: part.step.round,
          };
          break;
        case 'show_question':
          if (part.step.revealing) {
            part.step.revealing = false;
          } else if (part.step.names.length > 1) {
            part.step = {
              type: 'picking_question',
              names: part.step.names.slice(1),
              questions: pickQuestions(state),
              round: part.step.round,
            };
          } else if (
            numberOfRounds(
              participantsCount(state.presenter),
              activityKind(state.presenter),
            ) === 2 &&
            part.step.round === 0
          ) {
            part.step = {
              type: 'picking_question',
              names: shuffleParticipants(state),
              questions: pickQuestions(state),
              round: 1,
            };
          } else {
            dispatch.presenter.endCurrentPart(undefined);
            return;
          }
          break;
      }

      dispatch.presenter.updateCurrentPart(part);
    },

    // Participant actions
    async postQuestionDeckPicked(question: string, state) {
      const step = state.participant.activity?.parts[0].step;
      if (step?.type === 'picking_question') {
        await dispatch.participant.addEvent({
          type: 'question_deck_picked',
          data: {
            name: state.participant.name ?? '',
            question,
            round: step.round,
          },
        });
      }
    },

    async postQuestionDeckDone(_, state) {
      const step = state.participant.activity?.parts[0].step;
      if (step?.type === 'show_question') {
        await dispatch.participant.addEvent({
          type: 'question_deck_done',
          data: {
            name: state.participant.name ?? '',
            round: step.round,
          },
        });
      }
    },
  }),
});

const participantsForDoubleRound = 8;
const numberOfRounds = (participants: number, kind: 'short' | 'long') =>
  participants < participantsForDoubleRound && kind === 'long' ? 2 : 1;

const shuffleParticipants = (root: RootState) => {
  return _.shuffle(participantsNames(root.presenter));
};

const pickQuestions = (root: RootState) => {
  const part = getPart(root, 'question_deck');

  const answeredQuestions = getParticipation(
    root,
    'question_deck_picked',
    'roundIgnore',
  ).map((q) => q.question);
  const availableQuestions = part.attributes.questions.filter(
    (q) => !answeredQuestions.includes(q),
  );

  return _.sampleSize(availableQuestions, 3);
};
