// Import the functions you need from the SDKs you need
import {initializeApp} from 'firebase/app';
import {Buffer} from "buffer";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// @ts-ignore
const firebaseConfig = JSON.parse(Buffer.from(process.env.REACT_APP_FIREBASE_CONFIG, 'base64').toString());

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export default app;
