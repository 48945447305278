import {useState} from 'react';
import {FirebaseError} from 'firebase/app';
import {useLocation} from 'react-router-dom';

import {useDispatch, useStateSelector} from 'utilities/hooks';
import {iOS} from 'utilities/platform';
import ParticipantCard from './ParticipantCard';
import VisibilityTag from './VisibilityTag';
import Input from './Input';

const EnterPinForm = () => {
  const autoPin = new URLSearchParams(useLocation().search).get('pin');
  const dispatch = useDispatch().participant;
  const loading = useStateSelector(
    (s) => s.loading.effects.participant.joinActivity,
  );
  const loadingProgress = useStateSelector((s) => s.participant.joinProgress);
  const [pin, setPin] = useState(autoPin ?? '');
  const [name, setName] = useState('');
  const [pinError, setPinError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [browserError, setBrowserError] = useState(false);

  return (
    <ParticipantCard form title={'Balance Team'}>
      <Input
        label={'Activity code'}
        required
        autoFocus={!iOS() && !autoPin}
        value={pin}
        placeholder="A1B-2C3"
        className={`${pinError && 'border-danger'}`}
        disabled={loading}
        onChange={(e) => {
          var newPin = e.target.value;
          if (pin === `${newPin}-`) {
            newPin = newPin.slice(0, 2);
          } else {
            newPin = newPin.toUpperCase().replace(/[^A-Z0-9]/g, '');
            if (newPin.length >= 3) {
              newPin = `${newPin.slice(0, 3)}-${newPin.slice(3, 6)}`;
            }
          }
          setPin(newPin);
          setPinError(false);
        }}
      />

      <Input
        label={'Your name'}
        required
        autoFocus={!iOS() && !!autoPin}
        value={name}
        placeholder="Carl J."
        className={`${nameError && 'border-danger'}`}
        disabled={loading}
        onChange={(e) => {
          setName(e.target.value);
          setNameError(false);
        }}
      />

      <VisibilityTag
        type="you"
        tag="E2EE"
        caption="All data is end-to-end encrypted and is deleted when the activity ends."
      />

      {browserError ? (
        <p className="text-center">
          <p className="text-danger text-xl mb-1">Unsupported browser</p>
          <p className="text-sm">
            You might be in a QR scanner app.
            <br />
            Open this link using Safari or Chrome.
          </p>
        </p>
      ) : (
        <button
          className={`relative btn-primary block w-full tablet:w-auto overflow-hidden ${
            loading && loadingProgress ? ' bg-primary/50' : 'bg-primary'
          }`}
          disabled={loading || pin.length < 7 || name.trim().length === 0}
          onClick={async () => {
            try {
              await dispatch.joinActivity({pin, name});
              setPin('');
              setName('');
            } catch (e) {
              if (e instanceof FirebaseError) {
                setNameError(true);
              } else if (String(e).includes('WebAssembly')) {
                setBrowserError(true);
              } else {
                setPinError(true);
              }
            }
          }}
        >
          <div
            style={{
              width: `${
                loading && loadingProgress ? loadingProgress * 100 : 0
              }%`,
              transition: 'all 0.25s ease-in-out',
            }}
            className="absolute h-full top-0 left-0 rounded bg-primary"
          />
          <p className={'relative font-nunito text-lg text-white'}>
            <span>{loading ? 'Establishing E2EE...' : 'Join your team'}</span>
          </p>
        </button>
      )}
    </ParticipantCard>
  );
};

export default EnterPinForm;
