import {useState} from 'react';
import ReactDOM from 'react-dom';

import {useDispatch} from 'utilities/hooks';
import {PartReview} from 'models/partReview';
import NudgeEmail from './sidebar/NudgeEmail';
import Focus from './Focus';
import Say from './Say';

interface Props {
  part: PartReview;
}

const Review = ({part}: Props) => {
  const dispatch = useDispatch().partReview;
  const [done, setDone] = useState(false);
  const step = part.step;

  if (!step) {
    return null;
  }

  return (
    <>
      <div className="relative h-full">
        {step.type === 'show_intro' ? (
          <Say
            paragraphs={[
              'Thanks for your participation.',
              'Now take a moment to reflect on it.',
            ]}
            onCompleted={dispatch.nextStep}
          />
        ) : step.type === 'rating' ? (
          <Focus />
        ) : step.type === 'show_done' || step.type === 'email_entry' ? (
          <>
            <Say
              paragraphs={[
                'Mindletic Balance Team helps you to work better together.',
                "To gain more momentum till next time, we'll remind you to:",
              ]}
              reveal={part.attributes.nudge}
              onCompleted={() => setDone(true)}
            />
            {done && (
              <>
                <div className="btnBottomWrapper animate-fadeIn">
                  <button className="btn-primary" onClick={dispatch.nextStep}>
                    {'Complete training'}
                  </button>
                </div>
              </>
            )}
          </>
        ) : null}
      </div>
      {step.type === 'email_entry' &&
        ReactDOM.createPortal(
          <div className="modalWrapper" onClick={dispatch.nextStep}>
            <div
              className="modalContent animate-fadeIn"
              onClick={(e) => e.stopPropagation()}
            >
              <h2 className="mb-2">Team email?</h2>
              <NudgeEmail onDone={dispatch.nextStep} />
            </div>
          </div>,
          document.body,
        )}
    </>
  );
};

export default Review;
