import {useEffect, useState} from 'react';

import {useDispatch, useSelector} from 'utilities/hooks';
import Focus from './Focus';
import Say from './Say';
import {PartIntimacyReview} from 'models/partIntimacyReview';

interface Props {
  part: PartIntimacyReview;
}

const IntimacyReview = ({part}: Props) => {
  const dispatch = useDispatch();
  const [done, setDone] = useState(false);
  const step = part.step;
  const readingDone = useSelector((s) => s.partIntimacyReview.readingDone);

  useEffect(() => {
    if (readingDone) {
      dispatch.partIntimacyReview.nextStep();
    }
  }, [dispatch.partIntimacyReview, readingDone]);

  if (!step) {
    return null;
  }

  return (
    <div className="relative h-full">
      {step.type === 'show_intro' ? (
        <Say
          paragraphs={[
            'Before we begin, share how comfortable you feel with the current group setting.',
            'It will help Mindletic choose the most suitable activity for your team.',
            'Don’t worry, your answer is completely anonymous!',
          ]}
          onCompleted={dispatch.partIntimacyReview.nextStep}
        />
      ) : step.type === 'intimacy_rating' ? (
        <Focus />
      ) : step.type === 'show_reading' ? (
        <>
          <Say
            paragraphs={[
              'Thanks for your input!',
              'While Mindletic is preparing the most suitable activity for your team, check your phone!',
            ]}
            onCompleted={() => setDone(true)}
          />
          {done && (
            <>
              <Focus />
            </>
          )}
        </>
      ) : null}
    </div>
  );
};

export default IntimacyReview;
