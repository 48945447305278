interface Props {
  text: string;
  wrapperStyle?: string;
}

const DotParagraph = ({text, wrapperStyle}: Props) => (
  <div
    className={wrapperStyle ?? 'flex items-center px-4 largeDesktop:px-24 mb-6'}
  >
    <div className={'dot'} />
    <h4>{text}</h4>
  </div>
);

export default DotParagraph;
