import {createModel} from '@rematch/core';
import {RootModel, RootState} from '.';
import {
  getPart,
  getParticipation,
  PartChoiceOutput,
  partOverviewSelector,
  participantsCountSelector,
} from './parts';

interface ChoiceOption {
  attributes: {
    name: string;
    output: [
      {
        attributes: PartChoiceOutput;
      },
    ]; // Would become next input
  };
}

export interface PartChoice {
  key: string;
  layout: 'choice';
  attributes: {
    belongs_to: 'any' | 'long' | 'short';
    message: string;
    options: ChoiceOption[];
  };
  step?:
    | {
        type: 'show_options' | 'picking_option';
      }
    | {
        type: 'show_result';
        result: ChoiceOption;
      };
}
export interface EventChoice {
  type: 'choice';
  data: {optionIdx: number};
}

export const partChoice = createModel<RootModel>()({
  state: null,
  selectors: () => ({
    expectedEvents() {
      return participantsCountSelector((count) => count);
    },
    overview() {
      return partOverviewSelector((part: PartChoice) => ({
        // Get's merged with the next part overview
        prefix: 'Team *makes a choice*, then ',
        description: part.attributes.options.map((o) => ({
          option: o.attributes.name,
          value: o.attributes.output[0].attributes.question,
        })),
      }));
    },
  }),
  effects: (dispatch) => ({
    // Presenter
    startPart(_, state) {
      const part = getPart(state, 'choice');
      part.step = {type: 'show_options'};
      dispatch.presenter.updateCurrentPart(part);
    },
    nextStep(_, state) {
      const part = getPart(state, 'choice');
      switch (part.step?.type) {
        case 'show_options':
          part.step = {type: 'picking_option'};
          break;
        case 'picking_option':
          part.step = {
            type: 'show_result',
            result: result(state),
          };
          break;
        case 'show_result':
          dispatch.presenter.endCurrentPart(
            part.step.result.attributes.output[0].attributes,
          );
          return;
      }

      dispatch.presenter.updateCurrentPart(part);
    },

    // Participant
    async makeChoice(optionIdx: number) {
      await dispatch.participant.addEvent({type: 'choice', data: {optionIdx}});
    },
  }),
});

const result = (s: RootState) => {
  const part = getPart(s, 'choice');
  const votes = getParticipation(s, 'choice').map((e) => e.optionIdx);

  const results = new Array<number>(part.attributes.options.length).fill(0);
  votes.forEach((v) => results[v]++);
  const max = Math.max(...results);
  const winners = results.flatMap((v, i) => (v === max ? i : []));

  return part.attributes.options[
    winners[Math.floor(Math.random() * winners.length)]
  ];
};
