import {useEffect, useState} from 'react';
import {useDispatch, useStateSelector, useTimer} from 'utilities/hooks';
import {iOS} from 'utilities/platform';
import {PartAnonymousHat} from 'models/partAnonymousHat';
import IdleScreen from './IdleScreen';
import ParticipantCard from './ParticipantCard';
import SubmittedSuccessfullyCard from './SubmittedSuccessfullyCard';
import VisibilityTag from './VisibilityTag';

interface Props {
  part: PartAnonymousHat;
}

const AnonymousHat = ({part}: Props) => {
  const step = part.step;
  if (!step) {
    return null;
  }

  return (
    <>
      {step.type === 'entering_anonymous' ||
      step.type === 'entering_anonymous_short' ? (
        <EnteringAnswer part={part} />
      ) : step.type === 'show_anonymous' && !step.revealing ? (
        <VoteNext part={part} />
      ) : (
        <IdleScreen />
      )}
    </>
  );
};

const EnteringAnswer = ({part}: Props) => {
  const dispatch = useDispatch().partAnonymousHat;
  const [answer, setAnswer] = useState('');
  const [done, setDone] = useState(false);
  const loading = useStateSelector(
    (s) => s.loading.effects.partAnonymousHat.postAnswer,
  );
  const {setTimeout, clearTimeout} = useTimer();
  const short = part.step?.type === 'entering_anonymous_short';

  useEffect(() => {
    if (done && !short) {
      const timeout = setTimeout(() => setDone(false), 1500);
      return () => clearTimeout(timeout);
    }
  }, [done, short, setTimeout, clearTimeout]);

  if (done) {
    return <SubmittedSuccessfullyCard />;
  }

  return (
    <ParticipantCard
      form
      title={part.attributes.question}
      subtitle={
        short
          ? 'Submit a single response to the question.'
          : 'You can submit as many responses as you want.'
      }
    >
      <input
        required
        autoFocus={!iOS()}
        placeholder={part.attributes.prompt}
        onChange={(e) => setAnswer(e.target.value)}
      />
      <VisibilityTag type={'anon'} content={'response'} />

      <button
        className="btn-primary w-full tablet:w-auto"
        disabled={loading || answer.trim().length === 0}
        onClick={async () => {
          await dispatch.postAnswer({answer});
          setDone(true);
        }}
      >
        Submit anonymously
      </button>
    </ParticipantCard>
  );
};

const VoteNext = ({part}: Props) => {
  const dispatch = useDispatch().partAnonymousHat;
  const [voted, setVoted] = useState(-1);
  const loading = useStateSelector(
    (s) => s.loading.effects.partAnonymousHat.postVoteNext,
  );

  if (part.step?.type !== 'show_anonymous' || part.step.round === voted) {
    return <IdleScreen />;
  }

  return (
    <ParticipantCard
      className="sectionWrapper w-full tablet:w-1/2"
      title={"When you're done discussing this entry:"}
    >
      <button
        className="btn-primary w-full tablet:w-auto"
        disabled={loading}
        onClick={async () => {
          await dispatch.postVoteNext();
          setVoted(part.step?.type === 'show_anonymous' ? part.step.round : -1);
        }}
      >
        Done
      </button>
    </ParticipantCard>
  );
};

export default AnonymousHat;
