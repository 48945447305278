import {RematchRootState, RematchStore} from '@rematch/core';
import {RematchSelect} from '@rematch/select';
import {
  useSelector as useReSelector,
  useDispatch as useReDispatch,
  TypedUseSelectorHook,
  useStore,
} from 'react-redux';
import {Dispatch, RootState} from '../store';
import {ExtraModelsFromLoading} from '@rematch/loading';
import {RootModel} from 'models';
import {useCallback, useEffect, useRef, useState} from 'react';

// Rematch

export const useDispatch = () => useReDispatch<Dispatch>();
export const useStateSelector: TypedUseSelectorHook<RootState> = useReSelector;

type FullModel = ExtraModelsFromLoading<RootModel>;
type FullRoot = RematchRootState<RootModel, FullModel>;

export function useSelector<T>(
  selector: (
    sel: RematchSelect<
      RootModel,
      FullModel,
      RematchRootState<RootModel, FullModel>
    >,
  ) => (state: FullRoot) => T,
): T {
  const {select} = useStore() as RematchStore<RootModel, FullModel>;
  return useReSelector<FullRoot, T>(selector(select));
}

// Timers

export const useTimer = () => {
  const isPaused = useSelector((s) => s.presenter.isPaused);
  const pausedRef = useRef(isPaused);

  // Timeouts
  const timeouts = useRef<
    {
      timeout: NodeJS.Timeout;
      callback: () => void;
      remaining: number;
      start: Date;
      resumeTimeout?: NodeJS.Timeout;
    }[]
  >([]);

  const _clearTimeout = useCallback((timeout: NodeJS.Timeout) => {
    const i = timeouts.current.findIndex(
      (t) => t.timeout === timeout || t.resumeTimeout === timeout,
    );
    if (i !== -1) {
      const t = timeouts.current.splice(i, 1)[0];
      clearTimeout(t.resumeTimeout ?? t.timeout);
    }
  }, []);

  const _setTimeout = useCallback(
    (callback: () => void, ms: number) => {
      const timeout = pausedRef.current
        ? setTimeout(() => {}) // Ref dummy, that does nothing
        : setTimeout(() => {
            callback();
            _clearTimeout(timeout);
          }, ms);
      timeouts.current.push({
        timeout,
        callback,
        remaining: ms,
        start: new Date(),
      });
      return timeout;
    },
    [_clearTimeout],
  );

  // Interval timers
  const timers = useRef<
    {
      timer: NodeJS.Timer;
      callback: () => void;
      interval: number;
      start: Date;
      remaining?: number;
      remainingTimeout?: NodeJS.Timeout;
      resumeTimer?: NodeJS.Timer;
    }[]
  >([]);

  const _setInterval = useCallback((callback: () => void, ms: number) => {
    const timer = pausedRef.current
      ? setInterval(() => {}) // Ref dummy, that will be cleared
      : setInterval(callback, ms);
    if (pausedRef.current) {
      clearInterval(timer);
    }
    timers.current.push({timer, callback, interval: ms, start: new Date()});
    return timer;
  }, []);

  const _clearInterval = useCallback(
    (timer: NodeJS.Timer) => {
      const i = timers.current.findIndex(
        (t) => t.timer === timer || t.resumeTimer === timer,
      );
      if (i !== -1) {
        const t = timers.current.splice(i, 1)[0];
        if (t.remainingTimeout) {
          _clearTimeout(t.remainingTimeout);
        } else {
          clearInterval(t.resumeTimer ?? t.timer);
        }
      }
    },
    [_clearTimeout],
  );

  // Pausing effect
  useEffect(() => {
    pausedRef.current = isPaused;
    const now = new Date();
    if (isPaused) {
      // -- Pause --
      // Timeouts
      timeouts.current.forEach((t) => {
        clearTimeout(t.resumeTimeout ?? t.timeout);
        t.remaining -= now.getMilliseconds() - t.start.getMilliseconds();
      });

      // Intervals
      timers.current.forEach((t) => {
        if (!t.remaining) {
          clearInterval(t.resumeTimer ?? t.timer);
          t.remaining =
            (now.getMilliseconds() - t.start.getMilliseconds()) % t.interval;
        } else {
          // console.log('Do nothing as it is paused already');
        }
      });
    } else {
      // -- Resume --
      // Timeouts
      timeouts.current.forEach((t) => {
        t.resumeTimeout = setTimeout(t.callback, t.remaining);
        t.start = now;
      });

      // Intervals
      timers.current.forEach((t) => {
        const resume = () => {
          t.resumeTimer = setInterval(t.callback, t.interval);
          t.remaining = undefined;
          t.remainingTimeout = undefined;
        };
        if (t.remaining && !t.remainingTimeout) {
          t.remainingTimeout = _setTimeout(() => {
            resume();
          }, t.remaining);
        } else {
          resume();
        }
      });
    }
  }, [isPaused, _setTimeout]);

  return {
    setTimeout: _setTimeout,
    clearTimeout: _clearTimeout,
    setInterval: _setInterval,
    clearInterval: _clearInterval,
  };
};

interface Size {
  width: number | undefined;
  height: number | undefined;
}
export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<Size>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};
