import {FaMask} from 'react-icons/fa';
import {MdLock, MdRemoveRedEye} from 'react-icons/md';

interface Props {
  type: 'all' | 'you' | 'anon';
  tag?: string;
  content?: string;
  caption?: string;
}
const VisibilityTag = ({type, tag, content = 'entries', caption}: Props) => {
  const {Icon, color, ...fallback} = {
    all: {
      color: 'secondary',
      Icon: MdRemoveRedEye,
      tag: 'Public to team',
      caption: `Your ${content} and name will be visible to this team.`,
    },
    anon: {
      color: 'accent',
      Icon: FaMask,
      tag: 'Anonymous share',
      caption: `Only your ${content} will be visible to this team, not your name.`,
    },
    you: {
      color: 'dark',
      Icon: MdLock,
      tag: 'Private to you',
      caption: `Your ${content} will be saved on your device only, no one else will see it.`,
    },
  }[type];

  return (
    <p className="text-xs leading-5 mb-2 text-textCaption">
      <span className={`py-1 px-2 mr-1 rounded-full text-white bg-${color}`}>
        <Icon className="text-white text-sm mb-0.5 mr-1 inline-block" />
        {tag ?? fallback.tag}
      </span>
      {'- ' + (caption ?? fallback.caption)}
    </p>
  );
};

export default VisibilityTag;
