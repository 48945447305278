import {useEffect} from 'react';
import {MdClose} from 'react-icons/md';
import {useParams} from 'react-router-dom';
import {onSnapshot} from 'firebase/firestore';

import {useDispatch, useSelector, useStateSelector} from 'utilities/hooks';
import AnonymousHat from './components/AnonymousHat';
import Choice from './components/Choice';
import CreateTraining from './components/CreateTraining';
import GuessWho from './components/GuessWho';
import ThoughtShare from './components/ThoughtShare';
import QuestionDeck from './components/QuestionDeck';
import Review from './components/Review';
import StartTraining from './components/StartTraining';
import Sidebar from './components/sidebar';
import Footer from './components/Footer';
import IntimacyReview from './components/IntimacyReview';

const Presenter = () => {
  const dispatch = useDispatch().presenter;
  const userProgress = useSelector((s) => s.presenter.participantsProgress);
  const userCount = useSelector((s) => s.presenter.participantsCount);
  const activityPart = useSelector((s) => s.presenter.activityPart);
  const isPaused = useStateSelector((s) => s.presenter.paused);
  const isFocused = useStateSelector((s) => s.presenter.focus);
  const pin = useStateSelector((s) => s.presenter.pin);

  const homeScreen = activityPart?.step === undefined;

  const {token} = useParams();
  useEffect(() => {
    dispatch.opened(token);
  }, [dispatch, token]);

  const firestoreQuery = useSelector((s) => s.presenter.firestoreQuery);
  const hasQuery = firestoreQuery !== undefined;
  useEffect(() => {
    return (
      firestoreQuery &&
      onSnapshot(firestoreQuery, dispatch.handleNewSnapshot, () =>
        dispatch.clearState(),
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, hasQuery]);

  useEffect(() => {
    if (userCount > 0 && userCount === userProgress) {
      dispatch.nextStep();
    }
  }, [dispatch, userProgress, userCount]);

  return (
    <div
      className={`${
        isPaused || isFocused
          ? 'bg-dark backgroundDark'
          : 'bg-lighter background'
      } h-screen bg-contain bg-no-repeat bg-right-bottom`}
    >
      <div
        className={`flex flex-col-reverse desktop:flex-row p-8 h-screen overflow-scroll`}
      >
        <div className="desktop:w-3/5 desktop:h-full h-fit">
          <div
            className={`sectionWrapper ${
              userProgress !== undefined || isFocused
                ? 'bg-transparent p-0'
                : 'bg-white'
            } ${isPaused && !isFocused ? 'opacity-20' : ''}`}
          >
            {homeScreen ? (
              !pin ? (
                <CreateTraining />
              ) : (
                <StartTraining pin={pin} />
              )
            ) : activityPart.layout === 'anonymous_hat' ? (
              <AnonymousHat part={activityPart} />
            ) : activityPart.layout === 'choice' ? (
              <Choice part={activityPart} />
            ) : activityPart.layout === 'guess_who' ? (
              <GuessWho part={activityPart} />
            ) : activityPart.layout === 'thought_share' ? (
              <ThoughtShare part={activityPart} />
            ) : activityPart.layout === 'question_deck' ? (
              <QuestionDeck part={activityPart} />
            ) : activityPart.layout === 'review' ? (
              <Review part={activityPart} />
            ) : activityPart.layout === 'intimacy_review' ? (
              <IntimacyReview part={activityPart} />
            ) : null}
          </div>
          {homeScreen && <Footer />}
        </div>

        <Sidebar />

        {pin && (
          <div className="relative">
            <div className={'btnTopCornerWrapper'}>
              <MdClose
                className={'w-6 h-6 text-dark'}
                onClick={dispatch.clickedCloseButton}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Presenter;
