import {useSelector} from 'utilities/hooks';
import ProgressBar from '../ProgressBar';
import NudgeEmail from './NudgeEmail';
import UsersJoining from './UsersJoining';
import StartActivity from './StartActivity';

const Sidebar = () => {
  const sidebar = useSelector((s) => s.presenter.sidebar);
  const userProgress = useSelector((s) => s.presenter.participantsProgress);
  const userCount = useSelector((s) => s.presenter.participantsCount);

  if (!sidebar) {
    return null;
  }

  return (
    <div className={'sideSectionWrapper'}>
      {sidebar.startTraining && <StartActivity />}
      {sidebar.usersJoining && <UsersJoining />}
      {sidebar.activityProgress && <ProgressBar />}
      {sidebar.stepProgress && (
        <>
          <h4 className="mt-6">{'Teammates done:'}</h4>
          <h2>{`${userProgress} / ${userCount}`}</h2>
        </>
      )}
      {sidebar.nudgeEmail && <NudgeEmail />}
    </div>
  );
};

export default Sidebar;
