import {createModel} from '@rematch/core';
import {RootModel, RootState} from '.';
import {
  getPart,
  getParticipation,
  PartOutput,
  partOverviewSelector,
  participantsCount,
  participantsCountSelector,
} from './parts';

export interface PartThoughtShare {
  key: string;
  layout: 'thought_share';
  attributes: {
    belongs_to: 'any' | 'long' | 'short';
    message: string;
    question: string;
    prompt: string;
  };
  step?:
    | {
        type: 'show_intro' | 'entering_thought' | 'show_thought_share_intro';
      }
    | {
        type: 'show_who_next';
        round: number;
      }
    | {
        type: 'thought_share';
        round: number;
        name: string;
      };
}

export type EventThoughtShare =
  | {
      type: 'thought_share_ready';
      data: {
        name: string;
      };
    }
  | {
      type: 'thought_share_volunteer';
      data: {
        round: number;
        name: string;
      };
    }
  | {
      type: 'thought_share_done';
      data: {
        round: number;
        name: string;
      };
    };

export const partThoughtShare = createModel<RootModel>()({
  state: null,
  selectors: () => ({
    expectedEvents() {
      return participantsCountSelector((count) => 3 * count);
    },
    overview() {
      return partOverviewSelector((part: PartThoughtShare) => ({
        prefix: 'Everyone *takes notes* for themselves about:',
        description: part.attributes.question,
        suffix: 'Then, those who want *can share* their thoughts.',
      }));
    },
    hasVolunteer() {
      return (root: RootState) =>
        getParticipation(root, 'thought_share_volunteer').length > 0;
    },
    commentDone() {
      return (root: RootState) => {
        const participation = getParticipation(root, 'thought_share_done');
        return participation.length > 0;
      };
    },
  }),
  effects: (dispatch) => ({
    // Presenter
    startPart(data: PartOutput, state) {
      const part = getPart(state, 'thought_share');
      if (!part.attributes.question) {
        if (!data) {
          dispatch.presenter.endCurrentPart(undefined);
          return;
        }
        part.attributes.question = data.question;
        part.attributes.prompt = data.prompt;
      }
      part.step = {type: 'show_intro'};
      dispatch.presenter.updateCurrentPart(part);
    },
    nextStep(_, state) {
      const part = getPart(state, 'thought_share');
      switch (part.step?.type) {
        case 'show_intro':
          part.step = {type: 'entering_thought'};
          break;
        case 'entering_thought':
          part.step = {type: 'show_thought_share_intro'};
          break;
        case 'show_thought_share_intro':
          part.step = {type: 'show_who_next', round: 0};
          break;
        case 'show_who_next':
          const event = getParticipation(state, 'thought_share_volunteer')[0];
          if (!event) {
            dispatch.presenter.endCurrentPart(undefined);
            return;
          } else {
            part.step = {
              type: 'thought_share',
              round: part.step.round,
              name: event.name,
            };
          }
          break;
        case 'thought_share':
          const participants = participantsCount(state.presenter);
          if (participants > part.step.round + 1) {
            part.step = {type: 'show_who_next', round: part.step.round + 1};
          } else {
            dispatch.presenter.endCurrentPart(undefined);
            return;
          }
          break;
      }

      dispatch.presenter.updateCurrentPart(part);
    },

    // Participant actions

    async postThoughtShareReady(_, state) {
      const step = state.participant.activity?.parts[0].step;
      if (step?.type === 'entering_thought') {
        await dispatch.participant.addEvent({
          type: 'thought_share_ready',
          data: {
            name: state.participant.name ?? '',
          },
        });
      }
    },

    async postThoughtShareVolunteer(_, state) {
      const step = state.participant.activity?.parts[0].step;
      if (step?.type === 'show_who_next') {
        await dispatch.participant.addEvent({
          type: 'thought_share_volunteer',
          data: {
            name: state.participant.name ?? '',
            round: step.round,
          },
        });
      }
    },

    async postThoughtShareDone(_, state) {
      const step = state.participant.activity?.parts[0].step;
      if (step?.type === 'thought_share') {
        await dispatch.participant.addEvent({
          type: 'thought_share_done',
          data: {
            name: state.participant.name ?? '',
            round: step.round,
          },
        });
      }
    },
  }),
});
