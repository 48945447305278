import React from 'react';

import {useDispatch} from 'utilities/hooks';
import ParticipantCard from './ParticipantCard';

const PresentingInProgress = () => {
  const dispatch = useDispatch().presenter;
  return (
    <ParticipantCard
      title={"You're presenting"}
      subtitle={[
        'This browser is already in use for activity presentation.',
        "Use your mobile device to join the activity you're presenting.",
        'Otherwise, use incognito window or another browser on this device.',
      ]}
    >
      <button
        className="btn-muted w-full tablet:w-auto mt-4"
        onClick={async () => {
          if (
            window.confirm(
              'You are about to terminate the activity you are currently presenting.',
            )
          ) {
            await dispatch.endPresentationToParticipate();
          }
        }}
      >
        {'Stop presenting'}
      </button>
    </ParticipantCard>
  );
};

export default PresentingInProgress;
