const deep = {
  equal: (object1: any, object2: any) => {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    for (const key of keys1) {
      const val1 = object1[key];
      const val2 = object2[key];
      const areObjects = isObject(val1) && isObject(val2);
      if (
        (areObjects && !deep.equal(val1, val2)) ||
        (!areObjects && val1 !== val2)
      ) {
        return false;
      }
    }
    return true;
  },
  copy: <T>(object: T) => {
    return (object ? JSON.parse(JSON.stringify(object)) : object) as T;
  },
};

const isObject = (object: any) => object != null && typeof object === 'object';
export default deep;
