interface RadioChipProps {
  className?: string;
  selected: boolean;
  title: string;
  caption?: string;
  onClick: () => void;
}
const RadioChip = ({
  className,
  selected,
  title,
  caption,
  onClick,
}: RadioChipProps) => (
  <div className={`text-center ${className ?? ''}`}>
    <div
      className={`mb-1 ${
        selected ? 'border-primary' : 'border-textHover'
      } chip-radio`}
      onClick={onClick}
    >
      <div
        className={`h-5 aspect-square rounded-full mr-2 ${
          selected ? 'bg-primary' : 'border-textHover border'
        }`}
      />
      <p className={`${selected ? 'text-primary font-medium' : 'text-text'}`}>
        {title}
      </p>
    </div>
    <p className="text-xs largeScreen:text-base text-textCaption">{caption}</p>
  </div>
);

export default RadioChip;
